/* General Styling */
body {
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  .pricing-table-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .pricing-category-item {
    border-radius: 12px;
    width: 100%;
    padding: 30px;
    animation: slideIn 1.2s ease-in-out;
  }
  
  .pricing-category-title {
    font-size: 26px;
    margin-top: 50px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
    text-align: center;
    letter-spacing: 2px;
    position: relative;
  }
  
  .pricing-category-title::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 4px;
    background: #007bff;
  }
  
  .pricing-category-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .pricing-card-item {
    flex: 1;
    max-width: 320px;
    background: linear-gradient(145deg, #e8e8e8, #ffffff);
    border-radius: 12px;
    padding: 25px;
    box-shadow: 8px 8px 16px #d1d1d1, -8px -8px 16px #ffffff;
    transition: transform 0.4s, box-shadow 0.4s, background 0.4s;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .pricing-card-item:hover {
    transform: translateY(-10px);
    box-shadow: 12px 12px 24px #d1d1d1, -12px -12px 24px #ffffff;
    background: linear-gradient(145deg, #f0f0f0, #ffffff);
  }
  
  
  .pricing-card-title {
    font-size: 22px;
    font-weight: bold;
    color: #444;
    margin-bottom: 15px;
    z-index: 2;
    position: relative;
  }
  
  .pricing-card-price {
    font-size: 20px;
    font-weight: bold;
    color: #28a745;
    margin-bottom: 15px;
    z-index: 2;
    position: relative;
  }
  
  .pricing-card-feature {
    font-size: 16px;
    color: #666;
    margin: 8px 0;
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  
  .pricing-card-icon {
    color: #28a745;
    margin-right: 8px;
  }
  
  .duration-options {
    margin-top: 15px;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: row; /* Ensure items stack row-wise */
    gap: 10px; /* Add space between rows */
  }
  

  
  .duration-option-row:hover {
    background-color: #e6f7ff; /* Highlight row on hover */
  }
  
  
  .duration-option-value {
    font-size: 14px;
    color: #555;
  }
  
  
  .duration-options p {
    font-size: 14px;
    color: #444;
    margin-bottom: 5px;
  }
  
  .duration-options label {
    font-size: 14px;
    color: #555;
    margin-right: 10px;
  }
  
  .pricing-card-buy-now {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 20px;
    z-index: 2;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.4);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .pricing-card-buy-now:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pricing-card-item {
      max-width: 100%;
    }
  
    .pricing-category-title {
      font-size: 20px;
    }
  
    .pricing-card-title {
      font-size: 18px;
    }
  
    .pricing-card-price {
      font-size: 16px;
    }
  }
  .category-description {
    font-size: 1.1rem;
    color: #555;
    margin-top: 10px;
    font-style: italic;
  }
  