/* General Layout */
.printer {
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
  color: #333;
}

h1, h2 {
  color: #222;
  text-align: center;
  margin-bottom: 1.5rem;
}

/* Printer Card Layout */
.printer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.printer-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.printer-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.printer-image {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.printer-card h2 {
  font-size: 1.25rem;
  color: #333;
}

.printer-card p {
  margin: 0.5rem 0;
  color: #555;
  text-align: justify;
}

.details {
  color: #777;
}

/* Feature Comparison Table */
.feature-comparison {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.feature-comparison th, .feature-comparison td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.feature-comparison th {
  background-color: #333;
  color: white;
  font-weight: bold;
}

.feature-comparison td {
  background-color: #fafafa;
}

.feature-comparison tr:nth-child(even) td {
  background-color: #f0f0f0;
}

/* Testimonials Section */
.testimonials {
  margin-top: 3rem;
  text-align: left;
}

.testimonial {
  background: #fef3c7;
  padding: 1rem;
  border-left: 4px solid #ffc107;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.testimonial p {
  margin: 0.25rem 0;
}

.testimonial strong {
  color: #222;
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .printer {
    padding: 2rem;
width: 50%; 
margin-left: -70px;
color: #333;
  }
  

  .printer-card {
    width: 100%; /* Full width for tablet screens */
  }

  .feature-comparison {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .printer h1, .printer h2 {
    font-size: 1.5rem;
  }

  .printer-card h2 {
    font-size: 1.3rem;
  }

  .printer-card p {
    font-size: 0.9rem;
  }

  /* Make the feature comparison table responsive */
  .feature-comparison, .feature-comparison thead, .feature-comparison tbody, .feature-comparison th, .feature-comparison td, .feature-comparison tr {
    display: block;
    width: 100%;
  }

  .feature-comparison tr {
    margin-bottom: 15px;
  }

  .feature-comparison th, .feature-comparison td {
    text-align: right;
    padding: 8px;
    position: relative;
  }

  .feature-comparison th::before, .feature-comparison td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    font-weight: bold;
    text-align: left;
  }

  .feature-comparison th[data-label="Feature"], .feature-comparison td[data-label="Feature"] {
    display: none;
  }
}
