/* Base styles for the payment container */
.payment-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
padding-top: 80px;
padding-left: 20px;
padding-right: 20px;
transition: all 0.3s ease;
    opacity: 0;
    animation: fadeIn 1.2s ease-out forwards;
  }
  
  /* Add animation on page load */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Styles for each section */
  .payment-section {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(40px);
    animation: slideInUp 0.8s ease-out forwards;
    transition: transform 0.4s ease, opacity 0.4s ease;
    border-left: 5px solid transparent;
    background: linear-gradient(to bottom, #f0f0f0, #ffffff);
    position: relative;
  }
  
  /* Unique animation to slide each section up with rotation */
  @keyframes slideInUp {
    from {
      opacity: 0;
      transform: translateY(40px) rotate(5deg);
    }
    to {
      opacity: 1;
      transform: translateY(0) rotate(0);
    }
  }
  
  /* Adding hover effect on each section */
  .payment-section:hover {
    transform: scale(1.05) rotate(1deg);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    border-left: 5px solid #0056b3;
  }
  
  /* Animation on hover: Elevate section with a slight shadow */
  .payment-section:hover .payment-content {
    transform: translateY(-10px);
  }
  
  /* Styling for the content (title and description) */
  .payment-content {
    text-align: center;
    padding: 15px;
    color: #333;
    transition: transform 0.3s ease;
  }
  
  .payment-content h3 {
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  
  .payment-content p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    font-weight: 400;
  }
  
  /* Styling for the images */
  .payment-image {
    margin-top: 20px;
    transition: transform 0.4s ease-in-out;
  }
  
  /* Animation for image on hover */
  .payment-section:hover .payment-image img {
    transform: scale(1.1) rotate(5deg);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Styling for images */
  .payment-image img {
    width: 160px;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    transition: transform 0.4s ease, box-shadow 0.3s ease;
  }
  
  /* More interesting hover effect: Image scales and rotates */
  .payment-section:hover .payment-image img {
    transform: scale(1.1) rotate(5deg);
  }
  
  /* Responsive design for smaller screens */
  @media screen and (max-width: 768px) {
    .payment-container {
      grid-template-columns: 1fr;
    }
    .payment-image img {
      width: 130px;
    }
  }
  
  /* Add a smooth scroll effect for sections */
  html {
    scroll-behavior: smooth;
  }
  