/* Container styling */
.container {
    text-align: center;
    background-color: #f3f6f9;
    padding-top: 80px;
    color: #333;
}



.container p {
    font-size: 18px;
    color: #5a5a6d;
    margin: 0 auto 40px;
    text-align: justify;
    line-height: 1.7;
    margin-right: 20px;
    max-width: 800px;
}

/* Card grid layout */
/* Card grid layout with 3 columns */
.card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Always show 3 columns */
    gap: 25px;
    padding: 0 20px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
}

@media (max-width: 600px) {
    .card-grid {
        grid-template-columns: 1fr; /* 1 column for small screens */
    }
}

/* Card styling */
.card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    animation: fadeInUp 0.6s ease forwards;
    transform: translateY(10px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-right: 10px;
    border: 1px solid #e3e6eb;
}

.card:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.15);
}

/* Animated border on hover */
.card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(74, 144, 226, 0.15);
    transition: width 0.4s ease;
    z-index: 0;
}

.card:hover::before {
    width: 100%;
}

/* Card content styling */
.card-content {
    position: relative;
    z-index: 1;
}

.card h2 {
    font-size: 22px;
    color: #1e1e2d;
    margin: 0;
    font-weight: 700;
    transition: color 0.3s ease;
}

.card p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    transition: color 0.3s ease;
}

/* Icon styling */
.card-icon {
    font-size: 40px;
    color: #4a90e2;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.card:hover .card-icon {
    color: #357abd;
}

.card:hover h2 {
    color: #4a90e2;
}

.card:hover p {
    color: #333;
}

/* Animation for card entry */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
