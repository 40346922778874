/* Container for the entire component */
.connected-services-container {
padding-top: 80px;
    background-color: #f4f7fb;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

/* Heading */
h1 {
    font-size: 24px;
    color: #2d3436;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 60px;
    animation: fadeInTop 1.2s ease-out;
}

/* Fade-in from top animation */
@keyframes fadeInTop {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Service Card */
.service {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 85%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    animation: fadeInUp 1.2s ease-out forwards;
    border: none;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Service Hover Effect */
.service:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
    border: 1px solid #1abc9c;
}

/* Image Style */
.service h2 img {
    width: 55px;
    height: 55px;
    margin-right: 20px;
    transition: transform 0.4s ease, opacity 0.3s ease;
}

.service:hover h2 img {
    transform: scale(1.3);
    opacity: 0.9;
}

/* Service Title */
.service h2 {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: #2d3436;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
}

/* Hover effect on service titles */
.service:hover h2 {
    color: #1abc9c;
    transform: translateX(5px);
}

/* Paragraph Style */
.service p {
    font-size: 1.0rem;
    line-height: 1.7;
    color: #7f8c8d;
    max-width: 900px;
    margin: 0 auto;
    text-align: left;
    font-weight: 500;
    transition: color 0.3s ease;
}

.service:hover p {
    color: #2d3436;
}

/* Hover effect on the service card */
.service:hover {
    animation: scaleUp 0.4s ease-in-out;
}

/* Zoom animation for hover */
@keyframes scaleUp {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

/* Responsive design for medium screens */
@media screen and (max-width: 1024px) {
    .connected-services-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
    .connected-services-container {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 2.0rem;
    }

    .service h2 {
        font-size: 1.6rem;
    }

    .service p {
        font-size: 1rem;
    }

    .service {
        padding: 20px;
    }
}
