.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .h1{
    text-align: center;
  }
  
  .product-image {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    margin: 0 auto 20px;
  }
  
  .product-title {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .product-description {
    color: #666;
    font-size: 0.9em;
    text-align: justify;
  }
  

  /* Container for the image */
.product-image {
    width: 100%; /* Make the container take full width of its parent */
    height: 200px; /* Fixed height for the product image container */
    overflow: hidden; /* Ensure the image does not overflow the container */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px; /* Optional: Adds rounded corners */
  }
  
  /* Image itself */
  .product-image img {
    width: 50%;
    height: 70%;
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    border-radius: 8px; /* Match the border-radius with the container */
  }
  

  .solutions-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px;
    padding: 20px;
  }
  
  .solution-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
  }
  
  .solution-card:hover {
    transform: translateY(-5px);
  }
  
  .solution-card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .solution-card p {
    font-size: 0.95em;
    color: #666;
    text-align: justify;
    line-height: 1.5;
  }
  

  /* Responsive Styles */
@media (max-width: 768px) {
  .solutions-container, .product-grid {
    flex-direction: column;
    gap: 15px;
  }

  h1 {
    font-size: 1.5em;
  }

  .solution-card, .product-card {
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }

  .solution-card h3, .product-title {
    font-size: 1.1em;
  }

  .solution-card p, .product-description {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .solution-card, .product-card {
    width: 100%;
    padding: 10px;
  }

  .solution-icon {
    font-size: 1.5em;
  }

  h1 {
    font-size: 1.2em;
  }

  .solution-card h3, .product-title {
    font-size: 1em;
  }

  .solution-card p, .product-description {
    font-size: 0.8em;
  }
}









/* Product Grid */
.product-grid {
  display: grid;
  margin-left: 60px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  gap: 20px;
  padding: 5px;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.product-card:hover .product-image img {
  transform: scale(1.1);
}

.product-title {
  margin-top: 15px;
  color: #333;
}

.product-description {
  color: #666;
  margin-top: 10px;
  line-height: 1.5;
}

/* Solutions Container */
.solutions-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 30px;
}

.solution-card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 25px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.solution-card:hover {
  transform: translateY(-6px);
  background-color: #f4f4f4;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.solution-icon {
  font-size: 2.5em;
  color: #333;
  transition: transform 0.3s ease;
}

.solution-card:hover .solution-icon {
  transform: rotate(15deg);
}




/* Responsive Styles */
@media (max-width: 768px) {
  .solutions-container, .product-grid {
    grid-template-columns: 1fr 1fr;
  }

  .product-card, .solution-card {
    padding: 20px;
  }

  .product-title, .solution-card h3 {
    font-size: 1.2em;
  }

  .product-description, .solution-card p {
    font-size: 0.9em;
  }

  .solution-icon {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .solutions-container, .product-grid {
    grid-template-columns: 1fr;
  }

  .product-card, .solution-card {
    padding: 15px;
  }

  .product-title, .solution-card h3 {
    font-size: 1.1em;
  }

  .product-description, .solution-card p {
    font-size: 0.8em;
  }

  .solution-icon {
    font-size: 1.8em;
  }
}
