
  .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 5px;
    /* height: 5; */
    /* margin-top: ; */

    background-color: #ffffff1f;
  }
  
 
  
  .logo img {
    height: 40px;
  }
  

  
  .nav-actions button {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .sign-in {
    background: none;
    border: 1px solid #000;
  }
  
  .sign-up {
    background-color: #ff5a5f;
    color: white;
  }
  

  
  .left-content {
    max-width: 60%;
  }
  
  .left-content h1 {
    margin-bottom: 20px;
}

  
  .left-content p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    font-family: "lexend",sans-serif;

    border: none;
    font-size: 1.3rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: darkred;
  }
  
  
  .right-menu h3 {
    margin-bottom: 20px;
    margin-right: 50px;
    /* margin-top: 100px; */
    font-size: 18px;
  }
  
  .right-menu ul {
    list-style: none;
  }
  
  .right-menu li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .right-menu li:hover {
    transform: translateX(10px);
  }
  
  .right-menu img {
    width: 24px;
    /* margin-top: 100px; */
    height: 24px;
  }
  
  .explore-button {
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #000;
    cursor: pointer;
    margin-top: 20px;
  }
 
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .illustration {
    text-align: center; /* Center the image */
    margin-top: 6rem;
    justify-content: center;
    margin-left: 30%;
  }
  
  .illustration-img {
    width: 85vw; /* Make the width 100% of the viewport width */
    margin-left: -25%;
    margin-bottom: -170px;        
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
    border-top-left-radius: 1%; /* Round the top-left corner */
    border-top-right-radius: 1%; /* Round the top-right corner */
    /* Additional design features */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); /* Subtle shadow around the image */
    border: 3px solid rgba(255, 255, 255, 0.5); /* Light border around the image */
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover effects */
    position: relative; /* To control positioning of overlay */
    overflow: hidden; /* Hide any overflow outside the border */
  }
  
  /* Gradient overlay */
  .illustration-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)); /* Gradient from top to bottom */
    border-radius: 1%; /* Same rounded corners as the image */
    transition: background 0.3s ease; /* Smooth gradient transition */
  }
  
  /* Hover effect */
  .illustration-img:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5); /* More pronounced shadow on hover */
    filter: brightness(1.1); /* Make the image slightly brighter on hover */
    border-color: rgba(255, 255, 255, 0.8); /* Change the border color on hover */
  }
  
  /* Hover animation for the gradient overlay */
  .illustration-img:hover::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)); /* Darker gradient on hover */
  }
  
  /* Parallax effect */
  @keyframes parallax {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); /* Slight upward movement */
    }
    100% {
      transform: translateY(0); /* Reset back to original position */
    }
  }
  
  .illustration-img {
    animation: parallax 10s infinite ease-in-out; /* Apply parallax effect with continuous loop */
  }
  
  /* Border Hover Effect */
  .illustration-img:hover {
    border: 3px solid rgba(255, 255, 255, 1); /* Glow effect on border */
    transform: scale(1.05) rotate(2deg); /* Add slight rotation effect on hover */
  }
  
  /* Text Animation - Adding a title or caption on hover */
  .illustration-img:hover::after {
    content: "Explore More"; /* Text appears when hovered */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Text Fade-In Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .card-list {
    /* display: grid; */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card img {
    max-width: 100px;
    height: auto;
  }
  
  .card p {
    margin-top: 10px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .explore-button {
    margin-top: 20px;
    background-color: #0066ff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .explore-button:hover {
    background-color: #004db3;
  }
  





.cardviews p {
  font-family: "'Lexend', sans-serif";
  font-size: 10px;
  color: #333;
  letter-spacing: 0.5px;
}

/* Hover effect on cards */
.cardviews:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}










/* Style for the live chat box */
.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 999; /* Make sure it stays on top */
  display: none; /* Initially hidden */
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f1f1f1;
}

.chat-input {
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Chat toggle button */
.chat-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000; /* To ensure it stays on top */
}

.show {
  display: block; /* Show the chat when the class is applied */
}







/* Add styles for the chat box */
.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
}

.chat-box.show {
  display: flex;
}

/* Header of the chat box */
.chat-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Chat body with scroll */
.chat-body {
  padding: 10px;
  max-height: 200px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scroll */
  font-size: 14px;
}

/* Chat input */
.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}







.hero-container {
  position: relative;
  margin-bottom: 150px;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 10px;
  border-radius: 50%;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
}







.card-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150%;
  /* gap:10%; */
  margin-left: 7%;
  font-family: 'Lexend', sans-serif; /* Set the global font family */

  height: 250px;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

/* Individual cardviews styling */
.cardviews {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  height: 70%;
  margin-left: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.cardviews img {
  width:auto; /* Set a fixed width for the images */
  height: 100px;
 margin-top: 10px;
}

.cardviews p {
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Lexend', sans-serif; /* Set the global font family */

}

/* HeroSection.css */

/* Text Animation */
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .hero-content h3 {
    animation: fadeIn 1s ease forwards; /* Fade-in effect for the heading */
  }
  
  .hero-content p {
    animation: slideIn 1s ease forwards; /* Slide-in effect for the paragraph */
  }
  
  .cardviews {
    animation: fadeIn 1s ease forwards; /* Fade-in effect for each card */
  }
  
  .cardviews:nth-child(even) {
    animation-delay: 0.2s; /* Delay for even cards */
  }
  
  .cardviews:nth-child(odd) {
    animation-delay: 0.4s; /* Delay for odd cards */
  }


