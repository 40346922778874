/* Base Styling */
.tally-platform {
    background-color: #f4f7fc;
    color: #444;
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Header Section */
  .header h1 {
    font-size: 25px;
    color: #0062cc;
    margin-bottom: 20px;
    font-weight: 600;
    animation: fadeInUp 1s ease forwards;
  }
  
  .header p {
    color: #777;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeInUp 1.2s ease forwards;
  }
  
  /* Header Image */
  .header-image {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    border-radius: 15px;
    animation: fadeInUp 1.3s ease forwards;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
  }
  
  /* Section Titles with Icons */
  .development-platform h2,
  .extensions h2,
  .customization h2,
  .integrations h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 25px;
    color: #333;
    margin-bottom: 30px;
    font-weight: 500;
    animation: fadeInUp 1.3s ease forwards;
  }
  
  .development-platform i,
  .extensions i,
  .customization i,
  .integrations i {
    color: #0062cc;
    font-size: 1.0rem;
  }
  
  /* Features Section */
  .features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    padding: 40px 0;
  }
  
  /* Feature Item */
  .feature-item {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: left;
  }
  
  .feature-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
  
  .feature-item h3 {
    font-size: 24px;
    color: #0062cc;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .feature-item p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Section Images */
  .section-image {
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
    border-radius: 12px;
    animation: fadeInUp 1.5s ease forwards;
  }
  
  /* Hover Effects */
  .feature-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #0062cc, #00b4d8);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s ease;
  }
  
  .feature-item:hover::before {
    transform: scaleX(1);
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header h1 {
      font-size: 32px;
    }
  
    .features {
      grid-template-columns: 1fr;
    }
  
    .feature-item {
      margin-left:-25px;
      min-width: 100%;
      margin-bottom: 20px;
    }
  
    .header p {
      font-size: 18px;
    }
  
    .development-platform h2,
    .extensions h2,
    .customization h2,
    .integrations h2 {
      font-size: 26px;
    }
  }
  
  /* Button Styles (for call-to-action buttons) */
  .cta-button {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #0062cc;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button:hover {
    background-color: #004fa3;
  }
  
  .cta-button:focus {
    outline: none;
  }
  
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #0062cc;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f4f7fc;
  }
  /* General Section Styling */
.extensions {
  padding: 40px 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  text-align: center;
}

/* Header Styling */
.extensions h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extensions h2 i {
  margin-right: 10px;
  color: #3498db;
}

/* Paragraph Styling */
.extensions p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Heading for Key Benefits and Popular Extensions */
.extensions h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 600;
}

/* List Styling */
.extensions ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.extensions ul li {
  font-size: 1.1rem;
  color: #444;
  margin: 10px 0;
  padding-left: 25px;
  position: relative;
}

.extensions ul li strong {
  color: #3498db;
  font-weight: 600;
}

/* Icon for List Items */
.extensions ul li::before {
  content: '\2022';  /* Bullet symbol */
  font-size: 1.5rem;
  color: #3498db;
  position: absolute;
  left: 0;
  top: 0;
}

/* Image Styling */
.extensions .section-image {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* General Section Styling */
.customization {
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  text-align: center;
}

/* Header Styling */
.customization h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customization h2 i {
  margin-right: 10px;
  color: #3498db;
}

/* Subheading Styling */
.customization h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 600;
}

/* Paragraph Styling */
.customization p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* List Styling */
.customization ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.customization ul li {
  font-size: 1.1rem;
  color: #444;
  margin: 10px 0;
  padding-left: 25px;
  position: relative;
}

.customization ul li strong {
  color: #3498db;
  font-weight: 600;
}

/* Icon for List Items */
.customization ul li::before {
  content: '\2022';  /* Bullet symbol */
  font-size: 1.5rem;
  color: #3498db;
  position: absolute;
  left: 0;
  top: 0;
}

/* Image Styling */
.customization .section-image {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* General Section Styling */
.integrations {
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  text-align: center;
}

/* Header Styling */
.integrations h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integrations h2 i {
  margin-right: 10px;
  color: #3498db;
}

/* Subheading Styling */
.integrations h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 600;
}

/* Paragraph Styling */
.integrations p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* List Styling */
.integrations ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.integrations ul li {
  font-size: 1.1rem;
  color: #444;
  margin: 10px 0;
  padding-left: 25px;
  position: relative;
}

.integrations ul li strong {
  color: #3498db;
  font-weight: 600;
}

/* Icon for List Items */
.integrations ul li::before {
  content: '\2022';  /* Bullet symbol */
  font-size: 1.5rem;
  color: #3498db;
  position: absolute;
  left: 0;
  top: 0;
}

/* Image Styling */
.integrations .section-image {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
