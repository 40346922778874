/* TallyPrime Container */

body {
  font-family: "lexend",sans-serif;

}

.tallyprime-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

/* Section Styles */
.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
  border-radius: 12px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.section:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.section::before {
  content: "";
  position: absolute;
  font-family: "lexend",sans-serif;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 123, 255, 0.05);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.section:hover::before {
  opacity: 1;
}

.section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-top: 0;
  animation: slideUp 0.6s ease-out;
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.image {
  flex: 1;
  text-align: center;
}

.image img {
  width: 100%;
  max-width: 300px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image img:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.text {
  flex: 1;
  padding: 10px;
}

/* Reversing Sections for Alternate Layout */
.insightful-reports .text {
  order: 2;
}
.insightful-reports .image {
  order: 1;
}

/* Button Style */
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 14px rgba(0, 86, 179, 0.4);
  transform: translateY(-3px);
}

.button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 6px rgba(0, 86, 179, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: center;
  }

  .section .text,
  .section .image {
    order: initial;
  }

  .section h3 {
    font-size: 1.5rem;
  }

  .section p {
    font-size: 0.95rem;
  }
}
