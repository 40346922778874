.contacts {
  padding: 60px 0;
  padding-top: 100px;
  background-color: #f9f9f9;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 8px;
}

.left {
  width: 60%;
  padding: 20px;
  height: 60%;
  border-radius: 8px;
}

.right {
  width: 35%;
}

h1 {
  font-size: 25px;
  margin-bottom: 20px;
  color: #333;
  display: inline-block;
}

.contact-info .box {
  margin-bottom: 20px;
}

.contact-info .box h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-info .box p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.contact-form input, .contact-form textarea {
  width: 93%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
}

.primary-btn {
  background-color: #0d6efd;
  color: #fff;
  border: none;
  padding: 15px 25px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #0056b3;
}


/* Base Styling */
.contact-info-container {
  display: flex;
  flex-direction: column; /* Default: Vertical */
}

.contact-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.contact-item {
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 45%; /* Adjust width for horizontal */
}



.map-container {
  text-align: center; /* Center the map and heading */
  margin-top: 20px; /* Add some space above the map */
}

.map-container iframe {

  width: 96%;
}


.contact-item {
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition for smooth effect */
  padding: 10px; /* Padding for better touch targets */
  width: 45%;
  border-radius: 5px; /* Rounded corners */
}

.contact-item:hover {
  background-color: #f0f0f0; /* Light background on hover */
  transform: translateY(-2px); /* Slight lift effect */
}


.success-message {
  display: flex;
  align-items: center;
  color: green;
  margin-top: 15px;
  font-weight: bold;
}


/* ContactUs.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.modal-content h2 {
  color: green;
  margin-bottom: 10px;
}

.modal-content button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}
/* ContactUs.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.modal-content h2 {
  color: green;
  margin-bottom: 10px;
}

.modal-content button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.contact-info, .contact-form {
  margin-top: 20px;
}

.primary-btn {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primary-btn:hover {
  background: #0056b3;
}

@media screen and (max-width: 480px) {

  .contact-item {
    padding: 10px; /* Padding for better touch targets */
    width:120%;
    border-radius: 5px; /* Rounded corners */
  }
  
  h1 {
    font-size: 20px;
  }
  .primary-btn {
    font-size: 14px;
    padding: 8px 12px;
  }
  .contact-info .box h4 {
    font-size: 14px;
  }
  .contact-info .box p {
    font-size: 12px;
  }
  .modal-content {
    width: 95%;
    padding: 15px;
  }
  h1 {
    font-size: 22px; /* Adjust heading size */
  }

  .contact-info .box h4 {
    font-size: 16px; /* Slightly smaller headings */
  }

  .contact-info .box p {
    font-size: 14px; /* Adjust paragraph size */
  }

  .contact-form input, .contact-form textarea {
    width: 100%; /* Adjust input and textarea to full width */
    font-size: 14px; /* Reduce font size */
  }

  .primary-btn {
    font-size: 16px; /* Slightly smaller button text */
    padding: 10px 15px; /* Adjust padding for buttons */
  }

  .map-container iframe {
    width: 100%; /* Full width for the map */
  }

  .contact-details {
    flex-direction: column; /* Stack contact items vertically */
    gap: 15px; /* Reduce gap for smaller screens */
  }

  .contact-item {
   
    padding: 10px; /* Adjust padding */
  }

  .modal-content {
    width: 90%; /* Reduce modal width */
    padding: 20px; /* Adjust padding */
  }

  .modal-content h2 {
    font-size: 18px; /* Smaller modal heading */
  }

  .modal-content button {
    font-size: 14px; /* Smaller modal button text */
    padding: 8px 12px; /* Adjust padding */
  }
}



@media screen and (min-width: 500px) and (max-width: 700px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .left, .right {
    width: 100%; /* Take full width */
  }

  .left {
    margin-bottom: 20px; /* Add spacing between sections */
  }

  h1 {
    font-size: 22px; /* Adjust heading size */
  }

  .contact-info .box h4 {
    font-size: 16px; /* Slightly smaller headings */
  }

  .contact-info .box p {
    font-size: 14px; /* Adjust paragraph size */
  }

  .contact-form input, .contact-form textarea {
    width: 100%; /* Adjust input and textarea to full width */
    font-size: 14px; /* Reduce font size */
  }

  .primary-btn {
    font-size: 16px; /* Slightly smaller button text */
    padding: 10px 15px; /* Adjust padding for buttons */
  }

  .map-container iframe {
    width: 100%; /* Full width for the map */
  }

  .contact-details {
    flex-direction: column; /* Stack contact items vertically */
    gap: 15px; /* Reduce gap for smaller screens */
  }

  .contact-item {
    width: 100%; /* Full width for contact items */
    padding: 10px; /* Adjust padding */
  }

  .modal-content {
    width: 90%; /* Reduce modal width */
    padding: 20px; /* Adjust padding */
  }

  .modal-content h2 {
    font-size: 18px; /* Smaller modal heading */
  }

  .modal-content button {
    font-size: 14px; /* Smaller modal button text */
    padding: 8px 12px; /* Adjust padding */
  }
}



.map-link {
  text-decoration: none; /* Remove underline */
  display: inline-flex; /* Align icon and text horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Space between icon and text */
  border-radius: 8px; /* Rounded corners */
  background-color: #007bff; /* Blue background */
  padding: 10px 15px; /* Padding for clickable area */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
  color: white; /* Text color */
  font-size: 18px; /* Font size for the text */
  font-weight: bold; /* Make the text bold */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.map-link:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.map-link svg {
  color: white; /* Icon color */
}

.map-link span {
  font-size: 16px; /* Adjust text size */
  color: white; /* Ensure text color matches icon */
}
.address {
  line-height: 1.6; /* Improve readability with line spacing */
  color: #333; /* Set text color */
  font-size: 16px; /* Base font size */
  background-color: #f9f9f9; /* Light background for distinction */
  padding: 15px; /* Add padding */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.address h1 {
  font-size: 24px; /* Increase size for heading */
  margin-bottom: 10px; /* Add space below the heading */
  color: #007bff; /* Highlight heading */
}

.address br {
  line-height: 2; /* Adjust spacing between lines */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column; /* Stack inputs vertically */
  }

  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-form button.primary-btn {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .right.row {
    width: 70%;
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    padding: 10px;
    font-size: 13px;
  }

  .contact-form button.primary-btn {
    font-size: 14px;
    padding: 10px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-form select,
  .contact-form textarea,
  .contact-form button.primary-btn {
    font-size: 14px; /* Slightly smaller font for tablets */
    padding: 10px; /* Adjust padding */
  }
}

@media (max-width: 576px) {
  .contact-form select,
  .contact-form textarea,
  .contact-form button.primary-btn {
    font-size: 13px; /* Smaller font for mobile */
    padding: 8px; /* Compact padding for narrow screens */
  }

  .contact-form textarea {
    height: 100px; /* Reduce height for mobile */
  }

  .contact-form button.primary-btn {
    font-size: 14px;
    padding: 10px;
  }
}