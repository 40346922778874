/* WebDevelopment.css */
.WebDevelopment-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between sections */
    padding: 20px;
    background-color: #f9f9f9; /* Background color */
}

.transport-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgb(221, 218, 218);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.pr-purchase-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgb(209, 203, 203);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.e-warehouse-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgb(172, 165, 165);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.image {
    flex: 1;
}

.image img {
    width: 100%;
    height: auto;
    max-width: 250px; /* Control the image size */
}

.text {
    flex: 2;
    padding-left: 2rem;
}



@media (max-width: 768px) {
    .transport-section,
    .pr-purchase-section,
    .e-warehouse-section {
        flex-direction: column;
    }

    .image,
    .text {
        padding: 0;
        text-align: center;
    }

    .image img {
        max-width: 100%;
    }
}
