/* src/pages/ServicePage.css */

.body {
  font-family: "Lexend", sans-serif;
}

.web-page {
  padding: 20px;
  background-color: #f8f8f8;
}

.web-container {
  display: flex;
  gap: 30px; /* Increased gap between web cards */
  justify-content: center;
  margin-bottom: auto; /* Add bottom margin to web-container for spacing */
}

.web-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 30px; /* Add margin-bottom to web-card */
}

.web-card:hover {
  transform: scale(1.05);
}

.service-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 60px; /* Increased margin-bottom for more space below the heading */
}

h2 {
  font-size: 1.5em;
  margin: 10px 0;
  color: #444;
}

p {
  font-size: 1em;
  font-family: "Lexend", sans-serif;
}

.subitems-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* Increased gap between subitem cards */
  margin-top: 40px; /* Increased margin-top for more space */
}

.subitem-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 20px; /* Added margin-bottom for spacing between subitem cards */
}

.subitem-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.subitem-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.subitem-name {
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 10px;
  color: #444;
}

@media (max-width: 768px) {
  .web-container {
    flex-direction: column;
    align-items: center;
    gap: 30px; /* Add gap between items in mobile view */
  }

  .web-card {
    width: 90%;
  }

  .subitems-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .web-card {
    width: calc(100% - 40px);
  }

  h1 {
    font-size: 1.5rem;
  }

  .subitems-container {
    grid-template-columns: 1fr;
  }
}
