.timeline-container {
  max-width: 65%;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
}

/* Prevent image drag */
.timeline-container img {
  -webkit-user-drag: none;
  user-drag: none;
  pointer-events: none;
}



h2 {
  
  margin-bottom: 20px; /* Added margin for spacing */
  transition: color 0.3s; /* Color transition on hover */
}



.timeline-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555; /* Softened color for better readability */
  line-height: 1.5; /* Improved line height for readability */
}

.timeline {
  position: relative;
  padding: 20px 0;
  border-left: 3px solid #1a73e8;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  padding-left: 60px; /* Increased padding for better spacing */
  opacity: 0; /* Start hidden for animation */
  transform: translateY(20px); /* Start slightly lower for entrance effect */
  animation: fadeIn 0.5s forwards; /* Fade in animation */
  transition: transform 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
  background-color: #ffffff; /* Background color for each item */
  border-radius: 8px; /* Rounded corners for items */
  padding: 20px; /* Padding inside timeline items */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.timeline-item:hover {
  transform: translateX(5px); /* Slight shift on hover for effect */
  background-color: #f8f9fa; /* Light background on hover */
}

.timeline-item:nth-child(even) {
  animation-delay: 0.1s; /* Stagger animation for even items */
}

.timeline-item:nth-child(odd) {
  animation-delay: 0.2s; /* Stagger animation for odd items */
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: -12px; /* Adjusted for visual alignment */
  top: 0;
  height: 16px; /* Increased size for better visibility */
  width: 16px;
  background-color: #fff;
  border: 4px solid #1a73e8; /* Increased border thickness */
  border-radius: 50%;
}

.timeline-year {
  position: absolute;
  left: -70px; /* Adjusted for alignment */
  top: 0;
  background-color: #fff;
  color: #ffcc00;
  font-weight: bold;
  border: 1px solid #ffcc00;
  padding: 8px 12px; /* Increased padding */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
  transition: background-color 0.3s; /* Transition for hover effect */
}

.timeline-year:hover {
  background-color: rgba(255, 204, 0, 0.1); /* Highlight on hover */
}

.timeline-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: left; /* Align text to the left for readability */
}

.timeline-content p {
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
}

.timeline-presented {
  color: #999;
  font-style: italic;
}


.timeline-icon {
  margin-right: 8px; /* Adjust spacing between icon and text */
  color: #ffb936; /* Icon color */
  font-size: 2.5em; /* Icon size */
  transition: transform 0.3s; /* Smooth hover effect */
}

.timeline-icon:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}



.image-carousel {
  width: 100%;
  height: 700px; /* Set a fixed height for the carousel */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Space below the carousel */
  border: 2px solid #ffffff; /* Optional: Add border for styling */
  border-radius: 8px; /* Optional: Rounded corners */
}



.image-carousel img {
  opacity: 0; /* Start with image hidden */
  width: 100%;
  height:100%; /* Ensure images take full height */
}

.image-carousel img:nth-child(1) {
  opacity: 1; /* Show the first image */
}

.image-carousel img:nth-child(2) {
  opacity: 1; /* Show the second image */
  transform: translateX(100%); /* Slide in from the right */
}

.image-carousel img.show {
  opacity: 1; /* Show current image */
  transform: translateX(0); /* Slide to center */
}



.image-row {
  display: flex; /* Aligns items in a row */
  justify-content: center; /* Centers the images horizontally */
  margin-bottom: 20px; /* Space below the row */
}

.row-image {
  width: 300px; /* Set a fixed width for images */
  height: auto; /* Maintain aspect ratio */
  margin: 0 10px; /* Space between images */
  border-radius: 8px; /* Optional: round the corners */
}

.carousel-image {
  max-width: 100%; /* Ensures the carousel image fits the container */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Space above the carousel */
}

.timeline {
  margin-top: 20px; /* Space above the timeline */
}



.timeline-description {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.timeline {
  border-left: 2px solid #ccc;
  position: relative;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  padding-left: 20px;
}

.timeline-year {
  font-weight: bold;
  font-size: 1.5em;
}

.timeline-content {
  margin-left: 10px;
}

.timeline-icon {
  margin-right: 10px;
}






.timeline {
  border-left: 2px solid #ccc;
  position: relative;
}

.timeline-item {
  position: relative;
  padding-left: 20px;
  display: flex; /* Added for horizontal layout */
  align-items: center; /* Center align items vertically */
}

.timeline-year {
  font-weight: bold;
  font-size: 1.5em;
  width: 100px; /* Fixed width for year */
}

.timeline-content {
  margin-left: 10px;
  flex: 1; /* Allow this section to take up remaining space */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
}

.timeline-icon {
  margin-right: 10px;
}

.circular-image {
  width: 190px; 
   height: 190px;
  position: absolute;
  right: 50px;
  bottom:10px;
  /* margin-bottom: -40px; */
  /* border-radius: 50%; Makes the image circular */
  overflow: hidden; /* Ensures the image fits within the circular border */
  /* display: inline-block; Allows it to sit inline with other elements */
  /* margin-right: 10px; Space between the image and the icon */
}

.circular-image img {
  width: 100%; /* Make the image fill the circular div */
}



.award-image {
  width: 80px; /* Adjust the size as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-left: 20px; /* Space between the award details and the image */
}
.star-rating {
  display: flex; /* Align stars in a row */
}

.star-icon {
  color: gold; /* Change this to your desired star color */
  margin-right: 2px; /* Space between stars */
}

.circular-image:hover {
  transform: scale(1.2); /* Scale up the circular image on hover */
}

.circular-image:hover img {
  transform: scale(1.1); /* Slightly scale the image on hover */
}

/* Mobile Styles */
@media (max-width: 480px) {
  .timeline-container {
    width: 100%;
  }

  .timeline-container h2 {
    font-size: 1.2em;
  }

  .timeline-year {
    font-size: 0.2em;
  }

  .timeline-icon {
    font-size: 0.2em;
  }

  .content-details h3 {
    font-size: 0.2em;
  }

  .content-details p,
  .timeline-presented {
    font-size: 0.2em;
    margin-left: -64%;
  }

  .timeline-description {
    font-size: 1em;
    padding: 0 5px;
  }

  .image-row img {
    width: 60px;
    height: 60px;
  }

  .timeline-item {
    padding: 10px;
    max-width: 100%;
  }

  .circular-image {
    width: 60px;
    height: 60px;
  }

  .content-details {
    width: 100%;
    max-width: 100%;
  }


.star-rating {
  display: flex; /* Align stars in a row */
}
.timeline-icon {
  color: #fbff00; /* Icon color */
  font-size: 0.5em; /* Icon size */
  margin-left: -150px;
  transition: transform 0.3s; /* Smooth hover effect */
}

}

/* Small Screens: 480px to 800px */
@media (max-width: 800px) and (min-width: 480px) {
  .timeline-container {
    width: 180%; /* Slight margin for better view */
    margin: 0 auto;
  }

  .timeline-container h2 {
    font-size: 1.2em; /* Larger headings for better visibility */
    text-align: center;
    margin-bottom: 10px;
  }

  .timeline-year {
    font-size: 1em; /* Proportional year display */
    text-align: left;
    color: #666;
  }

  .timeline-icon {
    font-size: 10px;
    color: #ff9800; /* Icon highlight */
    margin-left: 10px;
  }

  .content-details h3 {
    font-size: 0.8em; /* Prominent section headings */
    margin: 10px 0;
    text-align: left;
  }

  .content-details p,
  .timeline-presented {
    font-size: 0.5em; /* Readable description */
    line-height: 1.4;
    margin-left: -15px; /* Reset margin */
    text-align: justify;
  }

  .timeline-description {
    font-size: 1em;
    padding: 10px 15px;
  }

  .image-row img {
    width: 80px;
    height: 80px;
    border-radius: 8px; /* Subtle rounding */
    margin: 5px;
  }

  .timeline-item {
    padding: 15px;
    max-width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for item */
    margin-bottom: 20px;
  }

  .circular-image {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Perfect circle */
  }

  .content-details {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }

  .star-rating {
    display: flex; /* Align stars in a row */
    gap: 5px;
    justify-content: center;
    margin: 10px 0;
  }

  .timeline-icon {
    font-size: 1.2em; /* Adjusted size */
    color: #ffcc00;
    margin-left: 0; /* Center aligned for icons */
    transition: transform 0.3s ease; /* Smooth hover effect */
  }

  .timeline-icon:hover {
    transform: scale(1.2); /* Interactive hover */
  }
}
