/* Main container */
.tally-prime-container {
    width: 100%;
    padding: 40px;
    background-color: #f9f9f9;
}

/* Header styles */
.tally-prime-header {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.tally-prime-header .icon {
    margin-right: 15px;
    font-size: 32px;
    color: #2d4f9a;
}



/* Section layout */
.tally-prime-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 3 equal columns */
    gap: 05px;
    padding: 0 05px;
    margin-right: 15%;
    margin-left: 15%;
    justify-items: center;
}

/* Card styling */
.tally-prime-card {
    background-color: #fff;
    width: 90%;
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    padding: -20px;
}


.tally-prime-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    background-color: #f1f1f1; /* Subtle background change on hover */
}

/* Card Title */
.tally-prime-card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #34495e;
}

/* Price styling */
.tally-prime-price {
    font-size: 28px;
    font-weight: bold;
    color: #2d4f9a;
    margin-top: 25px;
}

/* Button styling */
.tally-prime-button {
    background-color: #2d4f9a;
    color: white;
    padding: 16px 30px;
    border: none;
    border-radius: 8px;
margin-bottom: 20px;  
  cursor: pointer;
    margin-top: 25px;
    font-weight: bold;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.tally-prime-button:hover {
    background-color: #1d3f6e;
    transform: scale(1.05);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Inputs styles (radio, checkbox) */
.tally-prime-card input[type="radio"],
.tally-prime-card input[type="checkbox"] {
    margin: 12px 8px;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.tally-prime-card input[type="radio"]:focus,
.tally-prime-card input[type="checkbox"]:focus {
    transform: scale(1.2);
    background-color: #2d4f9a;
}

/* Input labels */
label {
    font-size: 16px;
    color: #34495e;
    cursor: pointer;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .tally-prime-section {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }

    .tally-prime-card {
        width: 90%;
        margin: 0 auto; /* Center cards */
    }

    /* Header adjustments */
    .tally-prime-header {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .tally-prime-price {
        font-size: 24px;
    }

    .tally-prime-button {
        padding: 12px 20px;
    }
}

/* Responsive design for very small screens */
@media (max-width: 480px) {
    .tally-prime-section {
        grid-template-columns: 1fr; /* Stack cards for smaller screens */
    }

    .tally-prime-card {
        width: 100%;
    }

    .tally-prime-header {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .tally-prime-price {
        font-size: 22px;
    }

    .tally-prime-button {
        padding: 10px 18px;
    }
}
