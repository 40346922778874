/* Base styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9fafb;
    color: #333;
  }
  
  .tally-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tally-container h1 {
    font-size: 25px;
    color: #2d3436;
    margin-top: 65px;
    margin-bottom: 10px;
  }
  
  .tally-container p {
    font-size: 1rem;
    color: #555;
    margin: 0 0 15px;
  }
  
  .tally-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Grid Item Styling */
  .grid-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .grid-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .grid-item h3 {
    font-size: 1.2rem;
    color: #2980b9;
    margin: 10px 0;
  }
  
  .grid-item p {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Icon Styling */
  .icon {
    font-size: 2rem;
    color: #2980b9;
    margin-bottom: 10px;
    animation: fadeIn 0.8s ease-in-out;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  