.autobackup-software {
    padding: 20px;
    text-align: center;
    background: linear-gradient(135deg, #e8edf3, #d0d8e8);
}

.autobackup-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
}

.software-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 25px;
    width: 280px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: slideIn 0.6s ease-out forwards;
    position: relative;
    opacity: 0;
}

@keyframes slideIn {
    from { transform: translateY(30px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.software-card:hover {
    transform: scale(1.05) rotate(1deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.software-icon {
    color: rgba(3, 160, 223, 0.7);
    margin-bottom: 15px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
}

.details {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
}

.image-container {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    position: relative;
    animation: fadeSlide 1.2s ease-out;
}

@keyframes fadeSlide {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Slideshow Image Styles */
.slideshow-image, .slideshow-image-bottom {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease, opacity 0.6s ease;
    animation: zoomInOut 5s infinite alternate;
}

@keyframes zoomInOut {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

/* Image Layout */
.image-container {
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
}

.top-image {
    width: 38%;
    margin-left: 110px;
    justify-content: center;
}

.bottom-image-container {
    display: flex;
    width: 100%;
    margin-right: 110px;
    justify-content: flex-end;
}

.bottom-image-container .slideshow-image-bottom {
    width: 45%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .software-card {
        width: 100%; /* Make the cards full-width on smaller screens */
    }

    .image-container {
        flex-direction: column;
    }

    .top-image, .bottom-image-container {
        width: 100%;
    }

    .slideshow-image, .slideshow-image-bottom {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .software-card {
        width: 100%; /* Full width for very small screens */
    }

    .slideshow-image, .slideshow-image-bottom {
        max-width: 100%; /* Ensure images are responsive */
    }
}
