.about-page {
   width: 90%;
   text-align: justify;
    font-family: "lexend", sans-serif;
  }
  
  .about-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .about-top-text {
    width: 60%;
  }
  
  .about-image {
    width: 35%;
  }
  
  .image-style {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .vision-mission {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
  }
  
  .card {
    width: 45%;
    background-color: #e6f5f2;
    border: 1px solid #dcdcdc;
    padding: 20px;
    border-radius: 8px;
  }
  
  .card h3 {
    color: #008080;
    text-align: center;
  }
  
  .values-section {
    text-align: center;
    margin-top: 50px;
  }
  
  .values-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .value-item {
    width: 22%;
    text-align: center;
  }
  
  .value-item img {
    width: 200%;
    height: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  


  .about-top {
    display: flex;
    justify-content: space-between; /* Distributes space between text and image */
    align-items: center; /* Vertically centers items */
    padding: 40px; /* Adds padding around the section */
    width: 100%;
    margin-left: 25px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 10px; /* Rounded corners for the section */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .about-top-text {
    width: 60%; /* Takes 60% of the section for text */
    margin-right: 20px; /* Space between text and image */
  }
  
  .about-top-text h2 {
    font-size: 2em; /* Larger font for the heading */
    color: #007BFF; /* Customize the heading color */
    margin-bottom: 15px; /* Space below the heading */
  }
  
  .about-top-text p {
    font-size: 1.1em; /* Slightly larger font for readability */
    line-height: 1.6; /* Improved line height for better readability */
    color: #333; /* Dark gray color for the text */
  }
  
  .about-image {
    width: 35%; /* Takes 35% of the section for the image */
    display: flex;
    justify-content: center; /* Centers image horizontally */
  }
  
  .image-style {
    max-width: 100%; /* Responsive image */
    border-radius: 10px; /* Rounded corners for the image */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the image */
  }
  


  .about-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #f4f4f4;
  }
  
  .about-top-text {
    width: 60%;
    font-family: "lexend", sans-serif;
  }
  
  .about-top h2 {
    font-family: "lexend", sans-serif;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-top p {
    font-family: "lexend", sans-serif;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .about-top-text p:last-child {
    font-weight: bold;
  }
 
 

  /* Vision and Mission Section Styles */
.vision-mission {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  padding: 40px;

  border-radius: 10px; /* Rounded corners */
    animation: fadeIn 1s ease; /* Fade-in animation */
}

.card2 {
  width: 30%;
  background-color: #ffffff; /* White background for cards */
  border: 1px solid #dcdcdc; /* Light border */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}




.card:hover {
  transform: translateY(-5px); /* Slightly lift on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.card h3 {
  text-align: center; /* Center headings */
  margin-bottom: 15px; /* Space below heading */
}

.card p {
  color: #333; /* Dark gray for text */
  line-height: 1.6; /* Improved line height */
  font-size: 1rem; /* Base font size */
}

/* Values Section Styles */
.values-section {
  text-align: center;
  margin-top: 50px;
  padding: 40px;
  border-radius: 10px; /* Rounded corners */
  animation: fadeIn 1s ease; /* Fade-in animation */
}

.values-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.value-item {
  width: 20%;
  text-align: center;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.value-item:hover {
  transform: scale(1.1); /* Scale up on hover */
}



/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(20px); /* Start slightly lower */
  }
  to {
    opacity: 1; /* End with full opacity */
    transform: translateY(0); /* Move to original position */
  }
}


.card2 {
  width: 30%;
  background-color: #ffffff; /* White background for cards */
  border: 1px solid #dcdcdc; /* Light border */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; /* Smooth transitions */
  position: relative; /* Positioning for the pseudo-element */
  overflow: hidden; /* Prevents overflow for the pseudo-element */
}

/* Hover effect with gradient background */
.card2:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.card2::before {
  content: ""; /* Empty content for the pseudo-element */
  position: absolute; /* Positioning it absolutely within the card */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  background: linear-gradient(135deg, #09efff, #0066ff); /* Gradient background */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for the gradient */
  z-index: 0; /* Behind the content */
}

.card2 h3, .card2 p {
  position: relative; /* Positioning for the text */
  z-index: 1; /* Ensures text is above the gradient */
}

/* Change the opacity of the gradient background on hover */
.card2:hover::before {
  opacity: 0.5; /* Change to desired opacity for the gradient */
}

/* Media query for tablets and mobile */
@media (max-width: 768px) {
  .about-top {
    flex-direction: column;
    padding: 20px;
  }
  
  .about-top-text {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .vision-mission {
    display: none;
    flex-direction: column;
    padding: 20px;
  }
  
  .card, .card2 {
    width: 100%;
  }
  
  .values-container {
    flex-direction: column;
    align-items: center;
  }
 
  .value-item {
    width: 50%;
    margin-left: -100px;
    margin-bottom: 20px;
  }
}
