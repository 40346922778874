/* General container styling */
.service-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
padding-top: 120px;
  gap: 30px;
  /* padding: 50px 20px; */
  border-radius: 20px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1.5s ease-out;
  overflow: hidden;
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Service item styling */
.service-item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1300px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4), 0 -10px 20px rgba(255, 255, 255, 0.05);
  text-align: center;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: transform 0.5s ease, box-shadow 0.5s ease, background 0.5s ease;
}

.service-item1:hover {
  transform: translateY(-10px) rotateX(3deg) scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  background: linear-gradient(135deg, #b9bed1, #ffffff);
}

/* Glow ring animation */
.service-item1::before {
  content: '';
  position: absolute;
  width: 400%;
  height: 400%;
  top: 50%;
  left: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent 60%);
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.6s ease;
  z-index: 0;
}

.service-item1:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.2);
}

/* Icon styling */
.service-icon {
  font-size: 80px;
  margin-top: -20px;
  color: #ffcc00;
  z-index: 1;
  transition: transform 0.6s ease, color 0.4s ease, filter 0.4s ease;
}

.service-item1:hover .service-icon {
  transform: rotate(360deg) scale(1.2);
  color: #ff8800;
  filter: drop-shadow(0 8px 15px rgba(255, 136, 0, 0.6));
}

/* Text styling */
.service-text h3 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: -30px;
  margin-bottom: 15px;
  z-index: 1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.service-item:hover .service-text h3 {
  color: #ff8800;
  transform: translateY(-5px) scale(1.1);
}

.service-text p {
  font-size: 18px;
  line-height: 1.8;
  z-index: 1;
  transform: translateY(20px);
  opacity: 0;
  animation: slideInText 0.8s ease forwards;
  animation-delay: 0.4s;
}

/* Slide-in text animation */
@keyframes slideInText {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-container {
    padding: 20px;
  }

  .service-item {
    padding: 20px;
  }

  .service-icon {
    font-size: 60px;
  }

  .service-text h3 {
    font-size: 22px;
  }

  .service-text p {
    font-size: 16px;
  }
}
