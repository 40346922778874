/* General Styles */
.service-page {
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #333;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #007BFF;
  transition: color 0.3s ease;
}

h1:hover {
  color: #0056b3;
}

/* Services Container */
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 20px;
  justify-content: center;
  transition: all 0.3s ease;
}

.service-card {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.service-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-card:hover .service-img {
  transform: scale(1.1);
}

.service-info {
  padding: 20px;
  background-color: #fff;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
}

.service-card h2 {
  font-size: 1.6rem;
  margin: 10px 0;
  color: #333;
}

.service-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

/* Hover Effects */
.service-card:hover p {
  opacity: 0.8;
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  .services-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .service-card {
    width: 100%;
    height: auto;
  }

  .service-card h2 {
    font-size: 1.4rem;
  }

  .service-card p {
    font-size: 1rem;
  }
}
