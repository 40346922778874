

  body {
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #f0f4f8;
    color: #333;
  }
  
  .benefits-container {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .benefits-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .benefits-header h1 {
    font-size: 22px;
    margin-bottom: 20px;
  
    font-weight: 600;
  }
  
  .benefits-header p {
    font-size: 18px;
    text-align: justify;
    color: #666;
    margin-left: 40px;
    margin-right: 40px;
    line-height: 1.7;
  }
  
  .benefits-key {
    text-align: center;
  }
  
  .benefits-key h2 {
    font-size: 22px;
    margin-bottom: 40px;
    color: #333;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  /* Benefits List */
  .benefits-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 50px;
  }
  
  .benefit-item {
    background-color: #ffffff;
    padding: 30px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    background-color: #e9f7fe;
  }
  
  .benefit-icon-container {
    background-color: #f4f7ff;
    padding: 15px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .benefit-item:hover .benefit-icon-container {
  }
  
  .benefit-icon {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease;
  }
  
  .benefit-item:hover .benefit-icon {
    transform: scale(1.1);
  }
  
  .benefit-title {
    font-size: 22px;
    color: #0052cc;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .benefit-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .benefit-content {
    font-size: 14px;
    text-align: justify;
    color: #777;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .benefits-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .benefits-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .benefits-header h1 {
      font-size: 30px;
    }
  
    .benefits-header p {
      font-size: 16px;
    }
  
    .benefits-key h2 {
      font-size: 26px;
    }
  
    .benefit-title {
      font-size: 20px;
    }
  }



/* Pricing Section Styles */
.pricing-section {
  margin: 40px auto;
  padding: 30px 20px;
  background: linear-gradient(135deg, #e3f2fd, #ffffff);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
}

.pricing-header {
  font-size: 1.8em;
  color: #4CAF50;
  margin-bottom: 20px;
  font-weight: bold;
}

.pricing-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.pricing-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.icon-wrapper {
  margin-bottom: 15px;
}

.pricing-item h3 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.pricing-item p {
  font-size: 1em;
  margin: 5px 0;
  color: #555;
}

.pricing-amount {
  font-size: 1.4em;
  font-weight: bold;
  color: #4CAF50;
  margin: 10px 0;
}

.pricing-gst {
  font-size: 1em;
  color: #888;
}
