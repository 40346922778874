/* General Styles */
.service-page {
  padding: 40px;
  background-color: #f4f4f9;
}

.main-title {
  text-align: center;
  font-family: "lexend", sans-serif;

  margin-bottom: 30px;
  color: #333;
}

.services-container, .antivirus-products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-card, .product-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 280px;
  text-align: center;
  padding: 20px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-card:hover, .product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.service-card img, .product-card img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.service-card h2, .product-card h2 {
  font-size: 1.2rem;
  margin-top: 15px;
  font-family: "lexend", sans-serif;

  color: #333;
}

.service-card p, .product-card p {
  font-size: 0.9rem;
  text-align: justify;
  color: #666;
  margin-top: 10px;
}

/* Hover Effect for Image */
.service-card:hover img, .product-card:hover img {
  transform: scale(1.05);
}

/* Active State */
.service-card.active, .product-card.active {
  background-color: #e6f7ff;
  transform: scale(1.05);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .service-card, .product-card {
    width: 45%; /* Two cards per row */
  }
}

@media (max-width: 768px) {
  .service-card, .product-card {
    width: 100%; /* One card per row */
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: 1.5rem;
  }

  .service-card, .product-card {
    width: 100%;
    margin-left: -30px;
  }

  .service-card h2, .product-card h2 {
    font-size: 1.1rem;
  }

  .service-card p, .product-card p {
    font-size: 0.85rem;
  }
}
