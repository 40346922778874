.tech-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .technology-used h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
  }

  .technology-used p {
    text-align: justify;

    margin-bottom: 30px;
  }
  
  .tech-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .tech-icons img {
    width: 80px;
  }
  
  .services-section {
    text-align: center;
  }
  
  .services-section h2 {
    font-size: 28px;
    margin: 0 auto 50px auto;
    margin-bottom: 20px;
  }
  
  .services-section p {
    max-width: 400px;
    margin:  auto 50px auto;
  }
  

  .android-apps {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .android-apps h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .android-apps p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .read-more-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .read-more-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design for smaller screens */
  @media (max-width: 768px) {
    .android-apps {
      flex: 1 1 100%; /* Full width on small screens */
    }
  }
  
  .read-more-btn {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .read-more-btn:hover {
    background-color: #e64a19;
  }
  
  .image-section {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }
  
  .image-section img {
    max-width: 45%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  

  .services-section {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .services-section h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .services-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    max-width: 800px;
    margin-left: auto;
    text-align: justify;
    margin-right: auto;
  }
  .services-section {
    justify-content: space-around; /* Optional: evenly space the items */
    align-items: flex-start; /* Align items to the start of the flex container */
    padding: 20px; /* Add some padding around the section */
}

.android-apps, .ios-apps {
    flex: 1; /* Each service section takes equal width */
    margin: 10px; /* Add some margin between service items */
    padding: 20px; /* Padding inside each service item */
    border: 1px solid #ccc; /* Optional: add a border */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
}

h3 {
    margin-bottom: 10px; /* Space below the headings */
}

p {
    margin-bottom: 15px; /* Space below the paragraphs */
}

.read-more-btn {
    background-color: #28a745; /* Button background color */
    color: white; /* Button text color */
    padding: 10px 15px; /* Button padding */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition for hover */
}

.read-more-btn:hover {
    background-color: #218838; /* Darker shade on hover */
}

    
.image-section {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }
  
  .image-section img {
    max-width: 45%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  