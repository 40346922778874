/* Global styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.biz-analyst-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.biz-analyst-container h1 {
  font-size: 1.5rem;
  margin-top: 50px;
  color: #2d3436;
  text-align: center;
  margin-bottom: 10px;
  animation: fadeInDown 1.2s ease-in-out;
}

.biz-analyst-container p {
  font-size: 1rem;
  color: #555;
  text-align: justify;
  margin: 0 0 15px;
  animation: fadeIn 1.5s ease-in-out;
}

.biz-analyst-container h2 {
  font-size: 1.5rem;
  color: #2980b9;
  text-align: center;
  margin: 40px 0 20px;
  transition: color 0.3s ease;
  animation: slideInFromLeft 1s ease-out;
}

.biz-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.feature-itmesi {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: scaleUp 1s ease forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.feature-itmesi:hover {
  transform: translateY(-8px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #ecf0f1;
  animation: glow 0.8s infinite alternate;
}

.feature-itmesi img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  animation: fadeIn 1.5s ease-in-out;
}

.feature-icons {
  flex: 0 0 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  border-radius: 50%;
  animation: rotateIn 1.2s ease-out;
}

.feature-item h3 {
  font-size: 1.2rem;
  color: #2980b9;
  margin-bottom: 10px;
  transition: color 0.3s ease;
  animation: fadeIn 1.3s ease-in-out;
}

.feature-item h3:hover {
  color: #3498db;
  transform: scale(1.1);
  animation: pulse 0.5s ease-in-out;
}

.feature-item ul {
  list-style-type: disc;
  padding-left: 20px;
}

.feature-item ul li {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.6;
  transition: color 0.3s ease;
  animation: slideInFromBottom 1s ease-out;
}

.feature-item ul li:hover {
  color: #2980b9;
}

/* Video styles */
.feature-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-in-out;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.5);
  }
  to {
    box-shadow: 0 0 20px rgba(0, 150, 255, 1);
  }
}

@keyframes rotateIn {
  from {
    transform: rotate(-360deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive design optimizations */
@media (max-width: 768px) {
  .biz-analyst-container h1 {
    font-size: 2rem;
  }

  .biz-analyst-container h2 {
    font-size: 1.6rem;
  }

  .feature-itmesi {
    padding: 15px;
  }

  .feature-icons {
    flex: 0 0 60px;
  }
}
