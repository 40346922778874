.footer {
  background-color: #3065b1;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-left: 20px; /* Balanced left margin */
  text-align: left;
}

.footer-section h3 {
  margin-bottom: 20px;
  font-size: 1.5em; /* Slightly larger font size for section headings */
  font-weight: bold;
  border-bottom: 2px solid #fff; /* Underline for section headings */
  padding-bottom: 10px; /* Space below the heading */
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between list items */
}

.footer-section ul li {
  font-size: 0.9em;
}

.contact-info {
  margin-top: 20px;
}

.contact-info p {
  margin: 5px 0;
}

.footer-bottom {
  width: 100%;
  border-top: 1px solid #fff;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.social-icons {
  display: flex;
  margin-left: 40px;
  gap: 15px; /* Space between social icons */
}

.social-icons i {
  font-size: 1.2em;
  transition: color 0.3s; /* Smooth transition for hover effect */
}

/* .social-icons i:hover {
  color: #007bff;
} */

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
  font-size: 0.8em;
}

.footer-links a:hover {
  text-decoration: underline;
}
.footer-link {
  color: white; /* Change this to your desired color */
  text-decoration: none; /* Remove underline if desired */
}

.footer-link:hover {
  color: rgb(255, 217, 0); /* Change the color on hover if desired */
}
.footer-bottom .social-icons a {
  color: white; /* Default color */
  font-size: 24px;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.footer-bottom .social-icons a:hover {
  color: rgb(255, 251, 0); /* Color on hover */
}


.footer-bottom .social-icons a:hover {
  color: #ff0000; /* default hover color */
}

/* Specific hover color for Facebook and LinkedIn */
.footer-bottom .social-icons a[href*="facebook.com"]:hover {
  color: #1466d1; /* Facebook blue */
}

.footer-bottom .social-icons a[href*="linkedin.com"]:hover {
  color: #1466d1; /* LinkedIn blue */
}




/* Mobile Responsive */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .footer-section {
    margin: 10px 0;
    text-align: center; /* Center-align text on mobile */
  }

  .footer-section h3 {
    font-size: 1.1em;
    margin-bottom: 15px;
  }

  .footer-section ul {
    gap: 5px;
  }

  .footer-section ul li {
    font-size: 0.8em;
  }

  .social-icons {
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-links {
    margin-top: 15px;
    text-align: center;
  }

  .footer-links a {
    font-size: 0.8em;
    margin: 0 10px;
  }
}