body {
  font-family: "lexend",sans-serif;

}

.tallyshow-container {
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.title {
  font-size: 1.75rem;
  margin-top: 40px;
  margin-bottom: 1rem;
    font-family: "lexend",sans-serif;
  color: #333;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.tallyshow-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: slideUp 0.6s ease-out;
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.tallyshow-section h2 {
  font-size: 1.5rem;
  font-family: "lexend",sans-serif;
  color: #333;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.product-list, .solution-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.product-item, .solution-item {
  background: linear-gradient(145deg, #f9f9f9, #e6e6e6);
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  font-family: "lexend",sans-serif;

  cursor: pointer;
}

.product-item:hover, .solution-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.product-item::before, .solution-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: rgba(0, 123, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-item:hover::before, .solution-item:hover::before {
  opacity: 1;
}

.icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 10px;
  animation: bounce 1.2s ease infinite alternate;
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-10px); }
}

.solution-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.solution-image:hover {
  transform: scale(1.05);
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.take-free-trial, .product-roadmap {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.take-free-trial:hover, .product-roadmap:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 14px rgba(0, 86, 179, 0.4);
  transform: translateY(-3px);
}

.take-free-trial:active, .product-roadmap:active {
  transform: translateY(2px);
  box-shadow: 0 2px 6px rgba(0, 86, 179, 0.2);
}


.read-more {
  background-color: #ffeb3b; /* Yellow color */
  border: none;
  padding: 10px;
  font-size: 14px;
  font-family: "lexend",sans-serif;

  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #ffca28; /* Darker yellow on hover */
}
