/* Overall content container styling */
.content-container {
    margin: 0 auto;
    padding: 100px;
    background: #f4f7fb;
    border-radius: 20px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(10px); /* Slight blur effect for content */
  }
  
  /* Confetti effect styles */
  .content-container .confetti {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  /* Grid Layout for Feature Sections */
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); /* Responsive columns */
    gap: 25px;
    padding: 20px;
    animation: fadeIn 0.8s ease-out;
  }
  
  /* Feature Section Styling */
  .feature {
    border-radius: 20px;
    padding: 25px;
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    perspective: 1000px; /* Enable 3D for flip effect */
    overflow: hidden;
    will-change: transform;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    background-clip: padding-box; /* Clean border appearance */
    z-index: 1;
  }
  
  /* .feature:hover {
    transform: scale(1.05) rotateY(5deg);
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    border: 1px solid #1e743b; 
  }

  .feature:hover .feature-content {
    transform: rotateY(180deg); 
  }
   */
  /* Feature Section Header */
  .feature h2 {
    font-size: 1.9rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #2d3a48;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Text shadow for enhanced contrast */
  }
  
  /* Feature Section Paragraph */
  .feature p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #5e6a75;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
    opacity: 0.9;
text-align: justify;
    transition: opacity 0.3s ease;
  }
  
  /* Hover effect on feature sections */
  .feature:hover p {
    opacity: 1; /* Full opacity on hover for better visibility */
  }
  
  /* Icon Style */
  .feature i {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #4caf50;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  /* Icon hover effect */
  .feature i:hover {
    transform: scale(1.2);
    color: #ffcc00; /* Neon glow effect on hover */
  }
  
  /* Scrollable content in feature */
  .feature p:last-child {
    margin-bottom: 0;
  }
  
  /* Confetti effect */
  .confetti {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  /* Animation for feature sections: Fade In */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Header Styling */
  h2 {
    color: #2d3a48;
    font-weight: bold;
    margin-top: 0;
  }
  
  /* Icon customization for better alignment */
  i {
    vertical-align: middle;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .feature h2 {
      font-size: 1.6rem;
    }
    .feature p {
      font-size: 0.95rem;
    }
    .features-grid {
      grid-template-columns: 1fr; /* Stack vertically on smaller screens */
    }
  }
  
  /* Mobile View Optimizations */
  @media (max-width: 600px) {
    .content-container {
      padding: 15px;
    }
  
    .feature {
      margin-bottom: 20px;
    }
  }
  
  