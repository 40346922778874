/* Basic Styling */
.server-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f4;
  animation: fadeIn 1.5s ease-in;
}

.server-content {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.page-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  animation: slideInLeft 1s ease-out;
}

.intro-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
  animation: fadeIn 1.5s ease-in;
}

/* Services Section */
.services-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service {
  background-color: #fff;
  padding: 20px;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeInUp 1s ease-in;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3rem;
  color: #333;
  margin-bottom: 15px;
  transition: color 0.3s;
}

.service-icon:hover {
  color: #007bff;
}

.service h2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.service p {
  font-size: 0.9rem;
  color: #555;
}

/* Server Image Section */
.server-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.server-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 2s ease-in;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .server-page {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .server-content {
    text-align: center;
    padding: 15px;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .service {
    width: 90%;
    margin-bottom: 20px;
  }

  .server-image img {
    max-width: 100%;
  }
}
