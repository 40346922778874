.security-container {
    text-align: justify;
    padding: 40px;
    background-color: #f4f4f4;
    font-family: "lexend", sans-serif;
  }
  

  .description {
    font-size: 16px;
    color: #555;
    margin-bottom: 40px;
    margin: auto;
  }
  
  .services {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
  }
  
  .service-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-right: 20px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-box img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .service-box h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .service-box p {
    font-size: 14px;
    text-align: justify;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .services {
      flex-direction: column;
      align-items: center;
    }
  
    .service-box {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  /* General Styles */
.security-container {
  padding: 40px;
  background-color: #f4f4f9;
}

h1, h2 {
  font-size: 2rem;
  text-align: center;
  color: #333;
}

.description {
  text-align: justify;
  color: #666;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.service-box {
  width: 280px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  cursor: pointer;
}

.service-box img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.service-box:hover img {
  transform: scale(1.05);
}

/* Camera Types Section */
.camera-types-title {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 40px;
}

.camera-types {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.camera-box {
  width: 200px;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.camera-box img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.camera-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.camera-box:hover img {
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .service-box, .camera-box {
    width: 45%; /* Two items per row */
  }
}

@media (max-width: 768px) {
  .service-box, .camera-box {
    width: 100%; /* One item per row */
  }
}
