.tally-containeri {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to a new line */
  gap: 30px;
  padding: 50px;
  animation: fadeInContainer 1.5s ease-out;
  overflow-x: auto; /* Allows horizontal scrolling if content overflows */
  margin-left: 10px;
  justify-content: flex-start;
}

.tally-containeri > * {
  flex: 1 1 calc(25% - 30px); /* 4 items per row, accounting for gap */
  box-sizing: border-box; /* Includes padding and border in width */
}

/* Section layout */
.tally-prime-section2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
  gap: 25px;
  padding: 0 15px;
  justify-items: center;
}

/* Card styling */
.tally-prime-card2 {
  background-color: #fff;
  width: 110%;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  padding: -20px;
}

  /* Fade-in animation for container */
  @keyframes fadeInContainer {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Section Styling */
  .tally-section {
    display: flex;
    margin-top: 90px;

    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15); /* Light shadow */
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    animation: fadeInSection 0.8s forwards;
    animation-delay: 0.3s;
    width: 320px; /* Fixed width for each section */
    flex-shrink: 0;
    scroll-snap-align: start;
    position: relative;
    transform: scale(0.95); /* Slight scaling effect for smooth appearance */
  }
  
  /* Fade-in animation for sections */
  @keyframes fadeInSection {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Content */
  .tally-section-content {
    padding: 20px 25px;
    flex: 1;
    background: #f9fafb;
    border-radius: 12px;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tally-section:hover .tally-section-content {
    background: #e3f2fd; /* Light blue background */
    transform: translateY(-5px); /* Lift effect */
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  }
  
  /* Title Styling */
  .tally-title {
    font-size: 20px;
    font-weight: 800;
    color: #333;
    margin-bottom: 15px;
    letter-spacing: 1.8px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  /* Hover effect on title */
  .tally-title:hover {
    color: #2196f3;
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  /* Points */
  .tally-points {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  
  .tally-point {
    font-size: 18px;
    color: #555;
    margin-bottom: 12px;
    line-height: 1.8;
    position: relative;
    padding-left: 30px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .tally-point:hover {
    color: #2196f3; /* Blue color on hover */
    transform: translateX(5px); /* Slide effect on hover */
  }
  
  .tally-point::before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
    color: #2196f3;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  /* Adding Tags/Labels for Distinction */
  .tally-label {
    display: inline-block;
    background: #2196f3;
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .tally-label:hover {
    background-color: #1976d2;
  }
  
  /* Image */
  .tally-section-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out, filter 0.3s ease, opacity 0.3s ease;
    border-bottom: 4px solid #2196f3;
  }
  
  /* Hover effect on image */
  .tally-section:hover .tally-section-image {
    transform: scale(1.1); /* Zoom-in effect */
    filter: brightness(85%); /* Darker image effect */
    opacity: 0.85; /* Slight transparency */
  }
  
  /* Hover Effects for Sections */
  .tally-section:hover {
    transform: translateY(-12px); /* Lift effect */
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .tally-container {
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      overflow-x: visible;
    }
  
    .tally-section {
      width: 100%;
      animation-delay: 0.4s;
    }
  
    .tally-section-image {
      height: 180px;
    }
  }
  
  /* Keyframes for Fade In Effect */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  







  