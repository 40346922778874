/* General Styling */
.taxation-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
padding-top: 80px; 
padding-left: 20px; 
padding-right: 20px;
  transition: all 0.3s ease-in-out;
    animation: fadeIn 0.6s ease-out, slideInFromLeft 0.7s ease-out;
  }
  
  .taxation-section {
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 0.6s forwards, scaleUp 0.5s ease-in-out, rotateIn 1s ease-out;
    transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
  }
  
  .taxation-section:hover {
    transform: translateY(-8px) scale(1.05);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
    background-color: #f9f9f9;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  /* Section Title Styling */
  .taxation-section h2 {
    font-size:22px;
    color: #222;
    margin-bottom: 15px;
    letter-spacing: 1px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    animation: bounceIn 0.8s ease-out;
  }
  
  .taxation-section h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0088cc;
    transform: scaleX(0);
    transform-origin: bottom right;
    animation: underlineExpand 0.6s ease-out forwards;
  }
  
  /* Section Text Styling */
  .taxation-section p {
    color: #555;
    line-height: 1.6;
    transition: color 0.3s ease, font-size 0.3s ease, letter-spacing 0.3s ease;
  }
  
  .taxation-section p:hover {
    color: #333;
    letter-spacing: 1.5px;
  }
  
  /* Image Box and Hover Effects */
  .taxation-section .image-box {
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }
  
  .taxation-section img {
    width: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease, opacity 0.5s ease, filter 0.5s ease, box-shadow 0.3s ease-in-out;
    opacity: 0.8;
    filter: brightness(90%);
  }
  
  .taxation-section img:hover {
    transform: scale(1.1) rotate(5deg);
    opacity: 1;
    filter: brightness(100%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  

  /* Add styles for the icon box */
.icon-box {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px; /* Adjust the height to match your layout */
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .taxation-container {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .taxation-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation Effects */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
  
  @keyframes rotateIn {
    0% {
      transform: rotate(-10deg);
      opacity: 0;
    }
    100% {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
  
  @keyframes underlineExpand {
    to {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    60% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes buttonFadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  