

/* Container Styling */
.e-warehouse-container {
  max-width: 90%;
  padding: 20px;
  margin-left: 50px;
  border-radius: 16px;
  animation: fadeIn 0.8s ease-out forwards;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Title Styling */
.e-warehouse-title {
  text-align: center;
  color: #2e7d32;
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

/* Grid Content Layout */
.e-warehouse-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Card Styling */
.e-warehouse-card {
  background: linear-gradient(120deg, #f1f8e9, #e8f5e9);
  padding: 25px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.e-warehouse-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Icon Styling */
.e-warehouse-icon {
  font-size: 3.5rem;
  color: #43a047;
  margin-bottom: 15px;
}

/* Text Styling */
.e-warehouse-text {
  color: #666;
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 15px;
  text-align: justify;
}

/* Button Styling */
.e-warehouse-btn {
  display: inline-block;
  background: linear-gradient(90deg, #43a047, #66bb6a);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

.e-warehouse-btn:hover {
  background: linear-gradient(90deg, #66bb6a, #43a047);
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.e-warehouse-btn:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(67, 160, 71, 0.8);
}

/* Toggle Button Styling */
.e-warehouse-toggle-btn {
  margin: 20px auto;
  display: block;
  padding: 15px 30px;
  background: linear-gradient(90deg, #2e7d32, #43a047);
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.e-warehouse-toggle-btn:hover {
  background: linear-gradient(90deg, #43a047, #2e7d32);
  transform: translateY(-3px);
}

/* Instructions Section */
.e-warehouse-instructions {
  background: #e8f5e9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.e-warehouse-instructions ol {
  list-style: decimal inside;
  padding-left: 0;
}

.e-warehouse-instructions li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #444;
}

/* Video Section */
.e-warehouse-video {
  display: block;
  max-width: 80%;
  margin: 20px auto;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}
/* General Section Styles */
.e-warehouse-trial,
.e-warehouse-offers {
  text-align: center;
  padding: 60px 40px;
  margin: 30px auto;
  background: linear-gradient(145deg, #f0fcff, #dff5fa);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.e-warehouse-trial:hover,
.e-warehouse-offers:hover {
  transform: translateY(-5px) rotateX(2deg) rotateY(1deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

/* Gradient Background Animation */
.e-warehouse-trial::before,
.e-warehouse-offers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, #d6f0ff, #f0fcff);
  z-index: -1;
  transition: background 3s ease-in-out;
}

.e-warehouse-trial:hover::before,
.e-warehouse-offers:hover::before {
  background: linear-gradient(145deg, #b2e4f8, #defcff);
}

/* Section Titles */
.e-warehouse-trial h4,
.e-warehouse-offers h4 {
  font-size: 2.8rem;
  font-weight: 800;
  color: #006494;
  margin-bottom: 20px;
  position: relative;
  text-shadow: 1px 1px 5px rgba(0, 100, 148, 0.2);
}

.e-warehouse-trial p,
.e-warehouse-offers p {
  font-size: 1.2rem;
  color: #444;
  line-height: 1.8;
  max-width: 600px;
  margin: 0 auto 20px;
}

/* Floating Icons */
.e-warehouse-trial::after,
.e-warehouse-offers::after {
  content: "🎁";
  font-size: 6rem;
  position: absolute;
  top: -30px;
  left: 30px;
  color: rgba(0, 100, 148, 0.1);
  animation: float-icon 5s ease-in-out infinite;
}

@keyframes float-icon {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

/* Buttons */
.e-warehouse-contact button,
.e-warehouse-trial button,
.e-warehouse-offers button {
  margin-top: 25px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background: linear-gradient(135deg, #007ac1, #0288d1);
  border: none;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), inset 0 0 0 3px rgba(255, 255, 255, 0.2);
  transition: all 0.4s ease;
}

.e-warehouse-contact button:hover,
.e-warehouse-trial button:hover,
.e-warehouse-offers button:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 100, 148, 0.4);
}

/* Contact Section */
.e-warehouse-contact {
  text-align: center;
  padding: 60px 40px;
  margin: 30px auto;
  background: linear-gradient(135deg, #e0faff, #f7feff);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
}

.e-warehouse-contact h4 {
  font-size: 2.6rem;
  font-weight: bold;
  color: #005b99;
  margin-bottom: 20px;
  text-shadow: 1px 1px 5px rgba(0, 91, 153, 0.2);
}

.e-warehouse-contact-info {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  max-width: 700px;
  margin: 0 auto 20px;
  animation: fade-in-slide 1s ease-in-out;
}

@keyframes fade-in-slide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Decorative Background Shapes */
.e-warehouse-contact::before {
  content: "";
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(2, 136, 209, 0.2), transparent 80%);
  border-radius: 50%;
  z-index: -1;
  animation: rotate-shape 6s linear infinite;
}

@keyframes rotate-shape {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .e-warehouse-content {
    grid-template-columns: 1fr;
  }

  .e-warehouse-title {
    font-size: 2rem;
  }

  .e-warehouse-btn,
  .e-warehouse-toggle-btn {
    width: 100%;
    font-size: 1rem;
    padding: 10px;
  }

  .e-warehouse-contact,
  .e-warehouse-trial,
  .e-warehouse-offers {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .e-warehouse-title {
    font-size: 1.8rem;
  }

  .e-warehouse-card {
    padding: 15px;
  }

  .e-warehouse-btn {
    font-size: 0.9rem;
  }

  .e-warehouse-video {
    max-width: 100%;
  }

  .e-warehouse-contact-info p {
    font-size: 0.9rem;
  }
}
