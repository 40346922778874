/* Container for the whole component */
.inventory-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
padding-top: 80px;
padding-left: 20px;
    background-color: #f0f0f5;
    box-sizing: border-box;
  }
  
  /* Styles for each section (Card) */
  .inventory-section {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.3s ease;
    opacity: 0;
    animation: fadeIn 1s forwards;
    background-color: #ffffff;
  }
  
  /* Animation for fading in the section */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Hover effect to scale, add shadow, and change background */
  .inventory-section:hover {
    transform: translateY(-15px) rotate(2deg);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    background-color: #f7f7f7; /* Slight background change on hover */
  }
  
  /* Styling for the icon */
  .inventory-section-icon {
    margin-top: 20px;
    color: #4CAF50; /* Green color for icons */
    transition: transform 0.4s ease, color 0.3s ease;
    font-size: 48px; /* Increase icon size */
  }
  
  /* Hover effect on icons: Scaling and rotating */
  .inventory-section:hover .inventory-section-icon {
    transform: scale(1.3) rotate(15deg); /* Slight rotation and scaling on hover */
    color: #00796b; /* Change icon color on hover */
  }
  
  /* Styling for section content */
  .inventory-section-content h3 {
    font-size: 22px;
    color: #333;
    font-weight: 600;
    margin-bottom: 15px;
    transition: color 0.3s ease;
  }
  
  /* Section content description */
  .inventory-section-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 25px;
    line-height: 1.7;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  /* Hover effect on content text */
  .inventory-section:hover .inventory-section-content h3 {
    color: #00796b; /* Change title color */
  }
  
  .inventory-section:hover .inventory-section-content p {
    color: #333; /* Darken description text */
  }
  
  /* Add animation to each section's appearance */
  .inventory-section:nth-child(even) {
    animation-delay: 0.2s; /* Staggered appearance of sections */
  }
  
  .inventory-section:nth-child(odd) {
    animation-delay: 0.4s;
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .inventory-container {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .inventory-container {
      grid-template-columns: 1fr;
      gap: 10px;
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .inventory-section {
      padding: 20px;
    }
    .inventory-section-content h3 {
      font-size: 1.5rem;
    }
    .inventory-section-content p {
      font-size: 0.9rem;
    }
  }
  