/* src/NotFound.css */
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #333;
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.not-found-title {
  font-size: 120px;
  font-weight: bold;
  animation: bounce 1.5s ease-in-out infinite;
  color: #ff6347;
}

.not-found-text {
  font-size: 20px;
  margin-top: 20px;
  opacity: 0;
  animation: fadeIn 2s ease forwards 1s;
  color: #555;
}

/* Bounce animation for the title */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Fade-in animation for the text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
