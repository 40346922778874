/* Gallery Container */
.frame-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 50px;
  background: linear-gradient(135deg, #e0eafc 20%, #f0f4f8);
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  animation: fadeInContainer 3s ease-out;
}

/* Enhanced Fade In for Container */
@keyframes fadeInContainer {
  from {
    opacity: 0;
    transform: translateY(100px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Title Styling */
.frame-title {
  font-size: 4.5rem;
  color: #2c3e50;
  margin-top: -30px;
  margin-bottom: 50px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 4px;
  animation: titleAnimation 2.5s ease-in-out forwards;
  background: linear-gradient(135deg, #ff6ec7, #ff9a8b);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.4);
}

/* Title Animation */
@keyframes titleAnimation {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Grid Layout for Photo Strings */
.frame-strings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 40px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  animation: fadeInGrid 2.5s ease-out;
  position: relative;
}

/* Fade-in effect for grid */
@keyframes fadeInGrid {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styling for each photo container */
.photo-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0 35px 90px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  cursor: pointer;
  border: 2px solid #f0f4f8;
  transform-origin: center;
  animation: zoomIn 1s ease-out;
  transition: transform 0.3s ease, box-shadow 0.5s ease, filter 0.5s ease;
  z-index: 1;
  perspective: 1000px;
  overflow: hidden;
}

/* 3D Flip Effect for Photo Container */
.photo-container:hover .photo {
  transform: rotateY(20deg) translateZ(60px);
  transition: transform 0.6s ease-out;
}

/* Hover effects with dynamic transform and shadow */
.photo-container:hover {
  transform: scale(1.15) rotate(6deg);
  box-shadow: 0 50px 150px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.2);
  animation: photoHoverEffect 0.4s ease-out;
  filter: brightness(1.2) contrast(1.1);
}

/* Hover Effect Animation */
@keyframes photoHoverEffect {
  from {
    transform: scale(1);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
  }
  to {
    transform: scale(1.15) rotate(6deg);
    box-shadow: 0 50px 150px rgba(0, 0, 0, 0.4);
  }
}

/* Image Styling */
.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  transition: transform 0.4s ease, filter 0.4s ease;
}

/* Hover effect on image (zoom, color manipulation and glitch) */
.photo-container:hover .photo {
  transform: scale(1.3);
  filter: brightness(1.5) contrast(1.1) saturate(1.4);
  animation: glitchEffect 1.2s infinite;
}

/* Glitch Effect Animation */
@keyframes glitchEffect {
  0% {
    transform: scale(1.3);
    filter: brightness(1.5) contrast(1.1) saturate(1.4);
  }
  20% {
    transform: scale(1.28) translateX(-8px);
  }
  40% {
    transform: scale(1.3) translateX(8px);
  }
  60% {
    transform: scale(1.28);
  }
  80% {
    transform: scale(1.3) translateX(-8px);
  }
  100% {
    transform: scale(1.3) translateX(8px);
  }
}

/* Year text styling */
.year {
  font-size: 1.8rem;
  color: #fff;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 18px;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 0.4s ease;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  z-index: 2;
  transition: opacity 0.4s ease-in-out;
}

/* Show year text on hover */
.photo-container:hover .year {
  opacity: 1;
}

/* Heart Icon with custom styling */
.heart {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 70px;
  height: 70px;
  background: url('/public/images/h22.png') no-repeat center center;
  background-size: contain;
  opacity: 0;
  transform: translateX(-50%) scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
  filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.5));
  z-index: 2;
}

/* Hover effect on heart icon */
.photo-container:hover .heart {
  opacity: 1;
  transform: translateX(-50%) scale(1.5);
}

/* 3D Zoom Animation */
@keyframes zoom3D {
  0% {
    transform: scale(0.8) rotateY(15deg);
  }
  100% {
    transform: scale(1) rotateY(0deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .frame-strings {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .frame-title {
    font-size: 3.8rem;
  }
  .frame-strings {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media (max-width: 480px) {
  .photo-container {
    height: 280px;
  }
  .heart {
    width: 60px;
    height: 60px;
  }
}

