.pricing-container {
    font-family: 'Lexend', sans-serif;
    text-align: center;
    padding: 40px 20px;
    background-color: #f3f7fc;
  }
  
  .pricing-title {
    font-size: 2.8rem;
    color: #232323;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .plans {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .plan-card {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    padding: 25px 20px;
    width: 320px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .plan-duration {
    color: #ff6f61;
    font-size: 1.3rem;
    font-weight: bold;
    background: #fff4f2;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 15px;
  }
  
  .plan-type {
    font-size: 2rem;
    color: #2d2d2d;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .plan-price {
    font-size: 1.7rem;
    font-weight: bold;
    color: #ff6f61;
    margin-bottom: 5px;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #888;
    font-size: 1rem;
  }
  
  .discount {
    font-size: 1rem;
    color: #ff6f61;
    background: #fff4f2;
    padding: 3px 8px;
    border-radius: 15px;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .plan-details {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .devices {
    margin: 15px 0;
  }
  
  .device-count {
    font-weight: bold;
    font-size: 1.1rem;
    color: #2d2d2d;
  }
  
  .device-note {
    font-size: 0.9rem;
    color: #666;
    margin-top: 5px;
  }
  
  .additional,
  .bulk-discount {
    font-size: 0.9rem;
    color: #666;
    margin-top: 10px;
  }
  
  .trial-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 30px;
  }
  
  .trial-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
  }
  
  .features-section {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
  }
  
  .features-section h3 {
    font-size: 1.8rem;
    color: #2d2d2d;
    margin-bottom: 20px;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .feature {
    font-size: 1rem;
    color: #666;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
  }
  
  .feature:before {
    content: "✔";
    color: #007bff;
    font-size: 1.2rem;
    margin-right: 8px;
  }
  
  @media (max-width: 768px) {
    .plans {
      flex-direction: column;
      align-items: center;
    }
  
    .plan-card {
      width: 100%;
      margin-left: -20px;
      max-width: 350px;
      margin-bottom: 20px;
    }
  }
  