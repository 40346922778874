.App {
  text-align: center;
  font-family: "lexend",sans-serif;
  min-height: 100vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1{ font-size: 25px;
.App {
  text-align: center;
  font-family: "lexend",sans-serif;
  min-height: 100vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1{ font-size: 25px;
  font-family: "lexend",sans-serif;
}
h2{ 
  font-family: "lexend",sans-serif;
}
h3{ 
  font-family: "lexend",sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  position: absolute;
  overflow: hidden; /* Prevent scrolling */
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: rgba(255, 255, 0, 0.7); /* Light yellow with transparency */
  border-radius: 50%; /* Make it circular */
  pointer-events: none; /* Ensures it's not selectable */
  transition: transform 0.15s ease; /* Smooth follow */
}

/* Responsive font sizes for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 10px; /* Adjusted font size for tablet */
  }
  
  .App-header {
    font-size: calc(10px + 1.5vmin); /* Adjust font size on smaller screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 10px; /* Further reduced font size for mobile */
  }

  .App-header {
    font-size: calc(10px + 1vmin); /* Smaller font size for mobile */
  }

  .highlight {
    width: 15px;
    height: 15px;
  }
}
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  position: absolute;
  overflow: hidden; /* Prevent scrolling */
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: rgba(255, 255, 0, 0.7); /* Light yellow with transparency */
  border-radius: 50%; /* Make it circular */
  pointer-events: none; /* Ensures it's not selectable */
  transition: transform 0.15s ease; /* Smooth follow */
}

/* Responsive font sizes for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 10px; /* Adjusted font size for tablet */
  }
  
  .App-header {
    font-size: calc(10px + 1.5vmin); /* Adjust font size on smaller screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 10px; /* Further reduced font size for mobile */
  }

  .App-header {
    font-size: calc(10px + 1vmin); /* Smaller font size for mobile */
  }

  .highlight {
    width: 15px;
    height: 15px;
  }
}