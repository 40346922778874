/* Container for vertical scrolling */
.scrollable-container {
    height: 200vh; /* Make it taller than the viewport for vertical scrolling */
    overflow-y: scroll; /* Enable vertical scroll */
    text-align: justify;
  }
  .circle-container {
    position: relative;
    width: calc(100vw - 40px); /* Adjust based on your desired margins */
    height: 50vh;
    display: flex;
    border-radius: 8px;
    align-items: center;
    perspective: 9000px; /* 3D perspective */
    background: linear-gradient(135deg, #ffffff, #606365); /* Example gradient */
    margin: 0 15px; /* 20px margin on left and right */
  }
  
  
  
  .circle {
    position: relative;
    width: 100%; /* Full width for the circle of images */
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d; /* Ensure 3D transforms work */
    animation: rotate 800s infinite linear; /* Continuous circle rotation */
  }
  
  .circle-item {
    position: absolute;
    transform: rotateY(calc(var(--i) * 36deg)) translateZ(500px); /* Spread images around the circle */
    transform-origin: center;
    transition: transform 1s;
  }
  
  .circle-item img {
    width: 100px;
    height: 200px;
    object-fit: cover;
    border-radius: 20px; /* Make images look like a phone */
  }
  
  @keyframes rotate {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg); /* Full 360-degree rotation */
    }
  }
  
  /* Optional: Style for scrollbar */
  .scrollable-container::-webkit-scrollbar {
    width: 12px;
  }
  
  .scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .container2 {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    display: block;
    align-items: flex-start;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 40px;
    border-radius: 8px;
  }
  


  .slider-video {
    width: 100%; /* Makes the video full width of the container */
    max-width: 600px; /* Sets the maximum width for the video */
    height: 180px; /* Sets a fixed height for the video */
    object-fit: cover; /* Ensures the video covers the specified area without distorting */
    border-radius: 10px; /* Optional: Adds rounded corners to the video */
  }
  


  .header-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .header-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  .mobile-shop-image {
    flex: 1;
    padding-right: 20px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .mobile-shop-image img {
    width: 100%;
    height: auto;
    margin-top: 60PX;
    border-radius: 10PX;
    transition: transform 0.3s ease;
  }
  
  .mobile-shop-image img:hover {
    transform: scale(1.05);
  }
  
  .header-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  
  .header-content h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #343a40;
    animation: fadeIn 0.6s ease forwards;
    opacity: 0;
    animation-delay: 0.3s;
  }
  
  .header-content p {
    color: #6c757d;
    text-align: justify;
    animation: fadeIn 0.6s ease forwards;
    opacity: 0;
    animation-delay: 0.6s;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  

  .features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .feature-item {
    flex: 1 1 200px; /* Ensures a minimum width for items */
    text-align: center;
    padding: 15px;
    border: 1px solid #e9ecef;
    border-radius: 10px;
    background: #ffffff;
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .feature-item i {
    font-size: 2rem;
    color: #007bff; /* Bootstrap primary color */
    margin-bottom: 10px;
  }
  
  .feature-item h4 {
    margin: 10px 0 5px;
    color: #343a40;
  }
  
  .feature-item p {
    color: #6c757d;
  }
  

  /* Responsive styling */
@media (max-width: 768px) {
  .features {
    gap: 10px;
  }

  .services-section,
  .section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .services-section img,
  .slider-video {
    max-width: 100%;
  }

  .tech-icons img {
    width: 60px;
    height: 60px;
  }

  .feature-item,
  .service-section h3 {
    font-size: 1.1rem;
  }

  .header-content h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .header-content h1 {
    font-size: 1.5rem;
  }

  .features {
    flex-direction: column;
  }

  .feature-item h4 {
    font-size: 1rem;
  }

  .technology-used h2 {
    font-size: 1.5rem;
  }

  .services-section h3,
  .section h3 {
    font-size: 1.3rem;
  }

  .tech-icons img {
    width: 50px;
    height: 50px;
  }
}
