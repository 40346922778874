/* Base styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
    color: #333;
  }
  

  .container {
    display: flex;
    transition: all 0.3s ease-in-out;
    min-height: 100vh; /* Ensure full screen height */
  }


 
  /* Sidebar List Styling */
  .sidebar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates 2 columns */
    gap: 20px;
  }
  
  .sidebar-item {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-item i {
    margin-right: 15px;
    font-size: 20px;
    height: 40px;
    transition: color 0.3s ease;
  }
  
  /* Hover Effect */
  .sidebar-item:hover {
    transform: translateY(-5px); /* Lift effect */
  }

  
  .sidebar-item.active i {
    color: #fff;
  }
  




  .content {
    flex: 1;
    padding: 20px 30px;
    animation: fadeIn 1s ease;
    overflow: hidden; /* Prevents content overflow */
  }
  
  .content-section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .content-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Section Titles */
  .content-section h2,
  .content-section h3 {
    color: #2d3436;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  
  .content-section h2 i,
  .content-section h3 i {
    margin-right: 8px;
    color: #0984e3;
  }
  
  .content-section p {
    color: #636e72;
    line-height: 1.6;
  }
  
  /* Image Styling */
  .content-section img {
    width: 40%;
    height: 40%;
    border-radius: 4px;
    margin-top: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
  }
  
  /* Animation Effects */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Media Queries */
  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      padding: 10px;
    }
  
    .content {
      padding: 15px;
    }
  }

/* Sidebar items */
.sidebar-item {
  border-bottom: 1px solid #34495e; /* Adds a border between items */
}

.sidebar-item a {
  display: block;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
}



.sidebar-item a i {
  margin-right: 10px; /* Adds space between icon and text */
}

/* Icon styling */
.sidebar-item a i {
  font-size: 1.3rem; /* Icon size */
  transition: color 0.3s ease;
}

/* Active state */
.sidebar-item a.active {
  background-color: #16a085;
  width: 100%;
  padding-left: 20px;
}
