/* General Styling */
.tally-definition {
    padding: 50px 20px;
    color: #333;
    background-color: #f7f7f7;
    text-align: center;
}

.tdl-section,
.capabilities-section,
.why-programmers-section {
    margin-bottom: 80px;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1.2s ease-out forwards;
}

.tdl-section h2,
.capabilities-section h2,
.why-programmers-section h2 {
    font-size:22px;
    color: #0033a0;
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.tdl-section h2::after,
.capabilities-section h2::after,
.why-programmers-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0033a0;
    transform: scaleX(0);
    transform-origin: bottom right;
    animation: underline 0.5s ease forwards;
}

.tdl-section p,
.capabilities-section p,
.why-programmers-section p {
    font-size: 1.1em;
    color: #666;
    text-align: center;
    max-width: 900px;
    margin: 20px auto;
    line-height: 1.7;
    letter-spacing: 0.5px;
}

/* Image Section */
.tdl-image {
    text-align: center;
    margin-top: 40px;
}

.tdl-image img {
    width: 100%;
    max-width: 650px;
    height: auto;
    border-radius: 15px;
    border: 2px solid #ddd;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tdl-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

/* Capabilities Section */
.capabilities-section .capability-item {
    margin: 30px 0;
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.capability-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #0033a0;
    border-radius: 12px 12px 0 0;
}

.capability-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.15);
}

.capability-item h3 {
    font-size: 1.3em;
    color: #0033a0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
}

.capability-item p {
    font-size: 1em;
    color: #555;
    max-width: 820px;
    margin: 0 auto;
    line-height: 1.6;
}

/* Programmer Benefits Section */
.why-programmers-section .programmer-benefits {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-programmers-section .programmer-benefits h3 {
    font-size: 1.5em;
    color: #0033a0;
    margin-bottom: 10px;
    font-weight: 600;
}

.why-programmers-section .programmer-benefits p {
    font-size: 1em;
    color: #555;
    max-width: 820px;
    margin: 0 auto;
    line-height: 1.6;
}

/* Animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes underline {
    0% {
        transform: scaleX(0);
        transform-origin: bottom right;
    }
    100% {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

/* Media Queries */
@media (max-width: 1024px) {
    .tdl-section h2,
    .capabilities-section h2,
    .why-programmers-section h2 {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .tdl-section, .capabilities-section, .why-programmers-section {
        text-align: left;
        padding: 0 20px;
    }

    .capabilities-section .capability-item,
    .why-programmers-section .programmer-benefits {
        margin: 20px 10px;
    }

    .tdl-section p,
    .capabilities-section p,
    .why-programmers-section p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .tdl-section h2,
    .capabilities-section h2,
    .why-programmers-section h2 {
        font-size: 1.8em;
    }

    .capability-item h3,
    .programmer-benefits h3 {
        font-size: 1.3em;
    }
}
