/* General Styles */
.hardware-services {
  padding: 60px 0;
  font-family: "Lexend", sans-serif;
  animation: fadeIn 1s ease-in;
}

.hardware-services h2 {
  font-size: 25px;
  text-align: center;
  margin-bottom: 50px;
  font-weight: bold;
  color: #2b2b2b;
  animation: fadeInDown 1.5s ease-in-out;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
  padding: 0 20px;
}

.service-item {
  width: 100%;
  max-width: 300px;
  padding: 25px;
  background: linear-gradient(145deg, #f8f8f8, #e2e2e2);
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transform: translateY(40px);
  opacity: 0;
  animation: slideInUp 0.7s forwards ease-out;
  animation-delay: calc(0.1s * var(--i));
  transition: transform 0.5s, box-shadow 0.5s, background 0.3s;
  overflow: hidden;
  position: relative;
}

.service-item:hover {
  transform: translateY(-10px) scale(1.02);
  background: linear-gradient(145deg, #ffffff, #e8e8e8);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 55px;
  margin-bottom: 20px;
  transition: color 0.4s, transform 0.4s;
  position: relative;
  z-index: 1;
}

.service-item:hover .icon {
  color: #004d40;
  transform: rotate(15deg) scale(1.15);
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  opacity: 0.9;
  transition: opacity 0.4s, transform 0.4s;
}

.service-item:hover .service-image {
  opacity: 1;
  transform: scale(1.05);
}

.service-item h3 {
  font-size: 24px;
  color: #37474f;
  margin-bottom: 12px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  transition: color 0.3s;
}

.service-item:hover h3 {
  color: #00695c;
}

.service-item p {
  font-size: 16px;
  text-align: justify;
  color: #616161;
  line-height: 1.7;
  padding: 0 10px;
  opacity: 0.9;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInDown {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes slideInUp {
  0% { transform: translateY(40px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

/* Media queries for smaller screens */

/* On Tablets and smaller screens */
@media (max-width: 768px) {
  .service-item {
    padding: 20px;  /* Reduce padding for smaller screens */
    width: 70%;  /* Decrease max-width for mobile */
    margin-left: -150px;
  }

  .icon {
    font-size: 45px;  /* Smaller icons on tablets */
  }

  .service-item h3 {
    font-size: 1.2rem;  /* Slightly smaller heading */
  }

  .service-item p {
    font-size: 0.9rem;  /* Slightly smaller text */
  }
}

/* Mobile (phones) */
@media (max-width: 480px) {
  .service-item {
    padding: 15px;  
    max-width: 100%;  /* Full width for mobile screens */
  }
  


  .icon {
    font-size: 40px;  /* Smaller icons for mobile */
  }

  .service-item h3 {
    font-size: 1.1rem;  /* Even smaller heading */
  }

  .service-item p {
    font-size: 0.85rem;  /* Even smaller text */
  }

  .service-image {
    border-radius: 8px;  /* Slightly more rounded images for mobile */
  }
}

/* Very small screens (portrait phones) */
@media (max-width: 360px) {
  .service-item {
    padding: 12px;  /* Further reduce padding on very small screens */
  }

  .icon {
    font-size: 35px;  /* Even smaller icons on very small screens */
  }

  .service-item h3 {
    font-size: 1rem;  /* Even smaller heading */
  }

  .service-item p {
    font-size: 0.8rem;  /* Even smaller text */
  }
}
/* Tablet and smaller screens */
@media (max-width: 768px) {
  .services-container {
    grid-template-columns: repeat(2, 1fr);  /* 2 columns on smaller screens */
    gap: 20px;  /* Reduce gap */

  }

  .hardware-services h2 {
    font-size: 1.75rem;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .service-item h3 {
    font-size: 1.2rem;
  }

  .service-item p {
    font-size: 0.9rem;
  }
}

/* Mobile (phones) */
@media (max-width: 480px) {
  .hardware-services {
    padding: 10px;
  }

  .hardware-services h2 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .services-container {
    grid-template-columns: 1fr;  /* 1 column on phones */
    gap: 15px;  /* Smaller gap */
  }

  .service-item {
    padding: 15px;  /* Adjust padding for smaller screens */
  }

  .service-item h3 {
    font-size: 1.1rem;
  }

  .service-item p {
    font-size: 0.85rem;
  }

  .service-image {
    border-radius: 5px;  /* Slightly round image corners */
  }
}

/* Very small screens (portrait phones, smaller tablets) */
@media (max-width: 360px) {
  .hardware-services {
    padding: 5px;
  }

  .hardware-services h2 {
    font-size: 1.3rem;
  }

  .service-item h3 {
    font-size: 1rem;
  }

  .service-item p {
    font-size: 0.8rem;
  }
}
