.gallery-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    grid-gap: 30px; /* Space between cards */
    margin: 20px;
    padding: 10px;
  }
  
  .gallery-item {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    perspective: 1000px; /* 3D perspective for flip effect */
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: translateY(-10px); /* Hover effect for the card */
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .flipped .flip-card-inner {
    transform: rotateY(180deg); /* Flip the card */
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide the back of the card when not flipped */
  }
  
  .flip-card-front {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .flip-card-front img {
    width: 80%; /* Image width inside the card */
    height: 80%;
    object-fit: contain;
  }
  

  .client-section {
    text-align: center;
    padding: 20px;
  }
  
  .client-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  

  /* Rain animation */
body.rain-animation {
  background: url('./gift.gif') repeat;
  background-size: cover;
}

.matched {
  opacity: 0.5;
  pointer-events: none;
}




/* Styles for the restart prompt */
.restart-prompt {
  position: fixed; /* Fix the position of the prompt */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for center alignment */
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  border: 2px solid #007bff; /* Border color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  padding: 20px; /* Spacing inside the box */
  z-index: 100; /* Ensure it appears above other content */
  text-align: center; /* Center text inside */
}

/* Styles for the buttons in the pop-up */
.restart-prompt button {
  margin: 10px; /* Space between buttons */
  padding: 10px 20px; /* Padding inside buttons */
  font-size: 16px; /* Font size for button text */
  color: white; /* Text color */
  background-color: #007bff; /* Button background color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Transition for hover effect */
}

.restart-prompt button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


.client-section {
  text-align: center;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  width: 150px;
  height: 150px;
  margin: 10px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}


.flip-card-back img {
  max-width: 100%; /* Ensures the image fits within the card */
  height: auto; /* Maintains image aspect ratio */
  margin-bottom: 10px; /* Adds space between the image and text */
}




.flip-card-back {
  position: absolute; /* Ensure it overlays correctly */
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden; /* Hide back when showing front */
  display: flex; /* Center the image */
  align-items: center; /* Vertically center the image */
  justify-content: center; /* Horizontally center the image */
}





.flip-card-back img {
  width: 100%; /* Adjust as necessary */
  display: flex;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional, for styling */
}













/* Responsive styles */
@media (max-width: 1024px) {
  .gallery-item {
    width: 120px; /* Adjust size for tablet screens */
    height: 120px;
  }

  .client-header {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .client-header {
    font-size: 1.5rem;
  }

  /* For mobile devices, reduce size further */
  .gallery-item {
    width: 100px;
    height: 100px;
  }

  .gallery-container {
    gap: 8px; /* Reduce spacing for smaller screens */
  }
}

@media (max-width: 480px) {
  .client-header {
    font-size: 1.2rem;
  }

  .gallery-item {
    width: 80px;
    height: 80px;
  }

  .gallery-container {
    gap: 5px;
  }
}