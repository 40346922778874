/* Global styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    margin: 0;
    padding: 0;
}

/* Container */
.tallyprime-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
}

/* Main title */
.main-title {
    font-size: 22px;
    font-weight: 800;
    color: #111;
    margin-bottom: 50px;
    letter-spacing: 1.5px;
    position: relative;
    display: inline-block;
}

.main-title::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #007bff, #00c6ff);
    transform: scaleX(0);
    transform-origin: bottom right;
    animation: titleEffect 0.6s forwards;
}

@keyframes titleEffect {
    100% {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

/* Feature Section */
.feature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    padding: 40px;
    border-radius: 15px;
    background: linear-gradient(135deg, #ffffff, #f8f8f8);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    transform: scale(0.98);
}

.feature:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.feature-icon {
    flex: 0 0 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    border-radius: 50%;
    transition: transform 0.4s ease-in-out, background 0.3s ease;
}

.feature-icon img {
    max-width: 60%;
    max-height: 60%;
    transition: transform 0.3s ease;
}

.feature:hover .feature-icon {
    transform: rotate(360deg);
    width: 07%;
    height: 10%;
    background: #00c6ff;
}

.feature:hover .feature-icon img {
    transform: scale(1.1);
}

.feature-content {
    flex: 1;
    text-align: left;
    padding: 10px;
}

.feature-content h2 {
    font-size: 22px;
    color: #007bff;
    margin-bottom: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

.feature-content p {
    font-size: 16px;
    color: #666;
    line-height: 1.8;
    transition: color 0.3s ease;
}

.feature:hover .feature-content p {
    color: #333;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .feature {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .feature-icon {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .main-title {
        font-size: 24px;
    }

    .feature-content h2 {
        font-size: 22px;
    }
}
