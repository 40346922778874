/* Main navigation bar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 39px;
  width: 98.5%;
  margin-left: 0%;
  border-radius: 4px;
  background-color: #3065b1;
  position: fixed;
  z-index: 999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: navbarSlideIn 0.5s ease-in-out;
}

@keyframes navbarSlideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Logo area styling */
.logo img {
  height: 58px;
  margin-top: 3px;
  padding: 05px;      
  margin-left: -20px;
  background-color: #e7e8ec;
  transition: transform 0.3s ease;
}

.logo img:hover {
  transform: scale(1.05);
}

/* Horizontal navigation links */
.nav-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  padding: 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffcc00;
  transform: translateY(-3px);
}

.nav-link.active {
  color: #007bff;
}

/* Menu icon for toggling sidebar */
.menu-icon {
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
  transition: transform 0.3s ease;
}

.menu-icon:hover {
  transform: rotate(90deg);
}



.sidebar.open {
  left: 0;
}

/* Sidebar navigation */
.sidebar-nav {
  list-style-type: none;
  padding: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 0;
  animation: sidebarItemsFadeIn 0.8s forwards;
}

.sidebar-nav li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.sidebar-nav li.active {
  background-color: #007bff;
  color: white;
}

.sidebar-nav li:hover {
  background-color: #e0e0e0;
  border-color: #007bff;
}

@keyframes sidebarItemsFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Sub-item section shown in a horizontal layout when a category is clicked */
.sub-item-container {
  margin-top: 20px;
  padding-left: 20px;
  width: 90%;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.card {
  background-color: #fff;
  padding: 20px;
  text-align: start;
  margin-left: -28px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 180px;
  max-width: 150px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100px;
  height: 70px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card img:hover {
  transform: scale(1.1);
}

.card p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-family: "lexend", sans-serif;
}



/* Inside Navigation.css, under the .card img styles */
.card img {
  width: 100px;
  height: 70px;
  object-fit: cover;
}

/* Sidebar with both horizontal and vertical visibility */
.sidebar {
  position: fixed;
  top: 60px;
  left: -300px;
  width: 220px;
  height: 90%;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  transition: left 0.5s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
}

.sidebar.open {
  left: 0;
}

/* Sidebar navigation */
.sidebar-nav {
  list-style-type: none;
  padding: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 0;
  animation: sidebarItemsFadeIn 0.8s forwards;
}

.sidebar-nav li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.sidebar-nav li.active {
  background-color: #007bff;
  color: white;
}

.sidebar-nav li:hover {
  background-color: #e0e0e0;
  border-color: #007bff;
}

/* Animation for sidebar navigation items */
@keyframes sidebarItemsFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Sub-item section shown in a horizontal layout when a category is clicked */
.sub-item-container {
  margin-top: 20px;
  padding-left: 20px;
  width: 90%;
  display: none;
  opacity: 0;
  animation: subItemFadeIn 0.5s forwards;
}
/* Show sub-items when the parent list item is active */
.sidebar-nav li.active + .sub-item-container {
  display: block;
  animation: subItemSlideIn 0.5s ease-out;
  opacity: 1;
}




/* For small devices (phones) */
@media (max-width: 480px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 10px 0;
    background: #444;
    border-radius: 5px;
  }

 

  .nav-links li {
    font-size: 0.9rem;
    margin: 8px 0;
    color: #fff;
  }

  .nav-link {
    color: #fff;
    padding: 10px 15px;
    text-decoration: none;
    display: block;
  }

  .menu-icon {
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    position: relative;
    top: 10px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 100vh;
    background: rgb(255, 255, 255);
    display: none;
    flex-direction: column;
    padding-top: 60px;
    z-index: 100;
    overflow-y: auto;
  }

  .sidebar.open {
    width: 53%;
    display: flex;
  }

  .sidebar-nav {
    flex-direction: column;
    width: 100%;
    padding: 1px;
    text-align: left;
  }

  .sidebar-nav li {
    font-size: 1.2rem;
    margin: -5px 0;
    color: #000000;
  }

  .card img {
    height: auto;
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .logo img {
    width: 100%;
    margin-top: -20px;
    margin-left: -30px;
    max-height: 50px;
  }
}

/* For medium devices (tablets) */
@media (max-width: 800px) and (min-width: 480px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #3065b1;
  }
  .logo img {
    width: 100%;
    margin-top: 7px;
    max-height: 65px;
  }
  .menu-icon {
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background: #333;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    font-size: 1rem;
    margin: 10px 0;
    color: #fff;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    display: none;
    flex-direction: column;
    padding-top: 60px;
    z-index: 100;
  }

  .sidebar.open {
    display: flex;
  }

  .sidebar-nav {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding: 20px;
    text-align: left;
  }

  .sidebar-nav li {
    font-size: 1.2rem;
    margin: 15px 0;
    color: #fff;
  }

  .card img {
    height: auto;
    max-width: 100%;
    border-radius: 10px;
  }


}
.nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
}

.sidebar-nav li:hover {
  color: #3065b1;
}

.card img {
  transition: transform 0.3s ease-in-out;
}

.card img:hover {
  transform: scale(1.05);
}

.logo img {
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1);
}
