/* General container styling */
.e-warehouse {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    text-align: center;
    color: #222;
    background: #f4f7f9;
  }
  
  .e-warehouse h3 {
    font-size: 2.5rem;
    color: #004085;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  /* Content container */
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin-top: 20px;
    padding: 10px;
  }
  
  /* Individual card styling */
  .cardw {
    background: #ffffff;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 25px;
    width: 320px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    position: relative;
  }
  
  .cardw:hover {
    transform: translateY(-8px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon styling */
  .icon {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 15px;
    display: block;
    text-align: center;
  }
  
  /* Paragraph inside cards */
  .cardw p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin: 0;
  }
  
  /* Background effect inside cards */
  .cardw::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #007bff, #00c6ff);
    border-radius: 12px 12px 0 0;
    z-index: 1;
  }
  
  /* Image styling */
  .warehouse-image {
    margin-top: 30px;
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .cardw {
      width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .cardw {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .e-warehouse h3 {
      font-size: 1.8rem;
    }
  
    .cardw {
      padding: 20px;
    }
  
    .icon {
      font-size: 2rem;
    }
  
    .cardw p {
      font-size: 0.9rem;
    }
  }
  