/* Container for the whole component */
.report-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
   padding-top: 80px; 
   padding-left: 20px;
   padding-right: 20px;
   background-color: #f4f6f9;
    box-sizing: border-box;
  }
  
  /* Styles for each section (Card) */
  .report-section {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.3s ease;
    opacity: 0; /* Initially hidden */
    animation: fadeInLeft 1s ease-in-out forwards; /* Apply fade in from left */
  }
  
  /* Add Fade In from Left Animation */
  @keyframes fadeInLeft {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Apply animation delay for staggered effect */
  .report-section:nth-child(even) {
    animation-delay: 0.2s; /* Staggered delay for even elements */
  }
  
  .report-section:nth-child(odd) {
    animation-delay: 0.4s; /* Staggered delay for odd elements */
  }
  
  /* Hover effect to scale, add shadow, and change background */
  .report-section:hover {
    transform: translateY(-15px) rotate(2deg);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    background-color: #f7f7f7; /* Slight background change on hover */
  }
  
  /* Styling for the report content (text) */
  .report-content h3 {
    font-size: 22px;
    color: #333;
    font-weight: 600;
    margin-bottom: 15px;
    transition: color 0.3s ease;
  }
  
  /* Section content description */
  .report-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 25px;
    line-height: 1.7;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  /* Hover effect on content text */
  .report-section:hover .report-content h3 {
    color: #00796b; /* Change title color */
  }
  
  .report-section:hover .report-content p {
    color: #333; /* Darken description text */
  }
  
  /* Styling for the image */
  .report-image img {
    max-width: 100%;
    height: auto;
    transition: transform 0.4s ease;
    border-radius: 10px;
  }
  
  /* Image hover effect: Zoom in */
  .report-section:hover .report-image img {
    transform: scale(1.1);
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .report-container {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .report-container {
      grid-template-columns: 1fr;
      gap: 10px;
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .report-section {
      padding: 20px;
    }
    .report-content h3 {
      font-size: 1.5rem;
    }
    .report-content p {
      font-size: 0.9rem;
    }
  }
  