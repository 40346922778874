/* CareerPage.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f4f8; /* Light background for a fresh look */
  color: #333;
}
/* Prevent image drag */
.culture-images img {
  -webkit-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

.career-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
}

.career-header {
  text-align: center;
  margin-bottom: 50px;
}

.career-header h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 700;
  /* text-transform: uppercase; Added for emphasis */
}

.career-header p {
  font-size: 1.2rem;
  color: #7f8c8d;
}

.career-section {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
  padding: 30px;
  transition: transform 0.3s; /* Smooth transform on hover */
}

.career-section:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.career-section h2 {

  color: #34495e;
  margin-bottom: 20px;
  border-bottom: 3px solid #3498db; /* Underline effect */
  padding-bottom: 10px;
}

.culture-images {
  display: grid; /* Use grid for a better layout */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px; /* Space between images */
}

.culture-image {
  width: 100%;
  height: 90%;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s; /* Hover effect */
}

.culture-image:hover {
  transform: scale(1.05); /* Zoom effect */
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0; /* Added margin for spacing */
}

.benefits-list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.1rem;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s; /* Added transition for hover */
}

.benefits-list li:hover {
  background-color: #ecf6ff; /* Light background on hover */
}

.icon {
  margin-right: 15px;
  color: #3498db;
  font-size: 1.5rem; /* Increased icon size */
}

.form-group {
  margin-bottom: 25px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-family: "lexend",sans-serif;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
select {
  width: 60%;
  font-family: "lexend",sans-serif;

  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
  border-color: #3498db; /* Focus effect */
}

input[type="file"] {
  margin-top: 5px;
  width: 61%;
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 5px;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.cancel-button,
.submit-button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.cancel-button {
  background-color: #FF0000;
  width: 15%;
  color: #fff;
}

.cancel-button:hover {
  background-color: #FF0000;
}

.submit-button {
  background-color: 	#008000;
  color: #fff;
}

.submit-button:hover {
  background-color: 	#008000;
}


.faq-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  transition: box-shadow 0.3s;
}

.faq-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Added shadow effect */
}

.faq-item p {
  margin: 5px 0;
  font-size: 1.1rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .career-header h1 {
    margin-top: 30px;
    font-size: 2.0rem; /* Smaller title on mobile */
  }

  .career-section {
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .form-actions {
    flex-direction: column; /* Stack buttons vertically */
  }

  .cancel-button,
  .submit-button {
    width: 100%; /* Full width for buttons on mobile */
    margin-bottom: 10px; /* Spacing between buttons */
  }
}
.gender-select {
  width: 710px; /* Full width */
  padding: 10px; /* Padding inside the dropdown */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Font size */
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff; /* White background */
  color: #333; /* Text color */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.gender-select:focus {
  border-color: #3498db; /* Border color when focused */
  outline: none; /* Remove outline */
}

.gender-select option {
  padding: 10px; /* Padding for options (may not always work) */
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .career-container {
    padding: 10px;
  }

  .form-actions {
    flex-direction: column; /* Stack buttons on smaller screens */
  }

  .submit-button,
  .cancel-button {
    width: 100%; /* Full width buttons */
    margin: 5px 0; /* Margin for spacing */
  }

  .career-section {
    margin: 20px 0; /* Less margin on smaller screens */
  }

  h1, h2, p {
    font-size: 1.2em; /* Adjust font sizes */
  }

  .culture-images {
    flex-direction: column; /* Stack images vertically */
  }

  .culture-image {
    max-width: 100%; /* Ensure images take full width */
  }
}
@media (max-width: 600px) {
  .gender-select {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; 
    width: 230px;/* Adjust padding for a better fit on mobile */
  }
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
  .career-section {
    padding: 1rem;
  }

  .career-form {
    gap: 1rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .career-section h2 {
    font-size: 1.5rem;
  }

  .career-section p {
    font-size: 0.9rem;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group select {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .thank-you-message,
  .error-message {
    font-size: 0.9rem;
  }
}
