/* General Styling */
.feature-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
padding: 80px; 
padding-left: 20px;
padding-right: 20px;
justify-items: center;
  }
  
  .feature-section {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    max-width: 350px;
    padding: 20px;
    position: relative;
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
  }
  
  .feature-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
  }
  
  .feature-text h3 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .feature-text p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .feature-button {
    text-decoration: none;
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 600;
    display: inline-block;
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  }
  
  .feature-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .feature-image {
    max-width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
  }
  
  .feature-section:hover .feature-image {
    transform: scale(1.05);
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Animation delays for each feature */
  .feature-section:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .feature-section:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .feature-section:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  .feature-section:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  .feature-section:nth-child(5) {
    animation-delay: 0.5s;
  }
  
  .feature-section:nth-child(6) {
    animation-delay: 0.6s;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .feature-container {
      grid-template-columns: 1fr;
      padding: 20px;
    }
  
    .feature-section {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  